import React from 'react';
import { PersonInviteFormProps } from './inquiry/inquiry';
import InquiryContactDetailsForm from './InquiryContactDetailsForm';
import InvitePreview from './InvitePreview';
import PersonInviteFormItems from './PersonInviteFormItems';
import { PEOPLE_TYPE } from './inquiry/inquiry';

export default ({
    isPreview,
    productionName,
    productionOptions,
    productionDateRange,
    isEmailModalOpen,
    peopleWithNoContact,
    entityDetails,
    getInquiryPreviewData,
    isPreviewLoading,
    previewData,
    peopleDisabled = true,
    filters = undefined
}: PersonInviteFormProps) => {
    return (
        <>
            {isEmailModalOpen ? (
                <InquiryContactDetailsForm name={'people'} itemsWithNoContact={peopleWithNoContact} />
            ) : isPreview ? (
                <InvitePreview
                    type={PEOPLE_TYPE}
                    entityDetails={entityDetails}
                    getInquiryPreviewData={getInquiryPreviewData}
                    isPreviewLoading={isPreviewLoading}
                    previewData={previewData}
                />
            ) : (
                <PersonInviteFormItems
                    productionName={productionName}
                    productionOptions={productionOptions}
                    productionDateRange={productionDateRange}
                    peopleDisabled={peopleDisabled}
                    filters={filters}
                />
            )}
        </>
    );
};
