import { searchByPage } from './searchPages';

export type SingleFilter = {
    filter: {
        identifier: string;
        dateMin?: string;
        dateMax?: string;
        maxValueExcluded?: boolean;
    };
    sortBy: 'enddate_ASC' | 'enddate_DESC';
};

type SearchProps = {
    activeOrganizationAccount: string;
    batchSize: number;
    filters: SingleFilter[];
    setBatchState: React.Dispatch<React.SetStateAction<any>>;
    setSearchData: React.Dispatch<React.SetStateAction<any>>;
    chunkSize?: number;
    includeAllActions?: boolean;
    additionalFragmentName?: string;
};

export function searchPageByType(
    resourceType: string,
    {
        activeOrganizationAccount,
        batchSize,
        filters,
        setBatchState,
        setSearchData,
        chunkSize = 2,
        includeAllActions = false,
        additionalFragmentName
    }: SearchProps
) {
    return filters.map(({ filter, sortBy }) =>
        searchByPage(
            resourceType,
            activeOrganizationAccount,
            batchSize,
            filter,
            sortBy,
            setBatchState,
            setSearchData,
            chunkSize,
            includeAllActions,
            additionalFragmentName
        )
    );
}

export const activitySearchByPage = (
    activeOrganizationAccount: string,
    batchSize: number,
    filters: SingleFilter[],
    setBatchState: React.Dispatch<React.SetStateAction<any>>,
    setSearchData: React.Dispatch<React.SetStateAction<any>>,
    chunkSize = 2,
    includeAllActions: boolean,
    additionalFragmentName?: string
) => {
    return searchPageByType('Activity', {
        activeOrganizationAccount,
        batchSize,
        filters,
        setBatchState,
        setSearchData,
        chunkSize,
        includeAllActions,
        additionalFragmentName
    });
};

export const reservationSearchPage = (
    activeOrganizationAccount: string,
    batchSize: number,
    filters: SingleFilter[],
    setBatchState: React.Dispatch<React.SetStateAction<any>>,
    setSearchData: React.Dispatch<React.SetStateAction<any>>,
    chunkSize = 2,
    includeAllActions = false,
    additionalFragmentName?: string
) => {
    return searchPageByType('Reservation', {
        activeOrganizationAccount,
        batchSize,
        filters,
        setBatchState,
        setSearchData,
        chunkSize,
        includeAllActions,
        additionalFragmentName
    });
};
