import { Dispatch, SetStateAction, MutableRefObject } from 'react'
import { cloneDeep } from 'lodash';
import { Production, Reference, Schedule, LocationInput, ReferenceInput } from 'sr-types/lib/production/v1/graphql';
import { ValidatorType } from '../../common/form/Validator';
import { EMPTY_SCHEDULE } from '../../common/form/widgets/ScheduleWidget';
import { getL10nDef } from '../../common/i18n/localization';

export const EMPTY_PRODUCTION_DATES: { productionDates: Schedule } = {
    productionDates: {
        dateRange: {
            ...cloneDeep(EMPTY_SCHEDULE),
            dateTimeDuration: {
                unit: 'week',
                value: 1
            }
        },
        autoSetDate: false
    }
};

export type ValidationRulesType = {
    [key: string]: ValidatorType[];
};
export type ProductionWithReference = Omit<Production, 'name'> & { name: Reference };

export const Production_Field_Names = {
    name: 'name',
    publicTitle: 'publicTitle',
    otherTitles: 'otherTitles',
    productionType: 'productionType',
    genre: 'genre',
    productionStatus: 'productionStatus',
    locations: 'locations',
    scheduleSection: 'scheduleSection',
    productionTrack: 'productionTrack',
    projectStatus: 'projectStatus',
    sectionTitle: 'sectionTitle',
    'productionGroup.productionReference': 'productionGroup.productionReference',
    'summary.about': 'summary.about',
    showAttributes: 'showAttributes',
    'budget.currencyCode': 'budget.currencyCode',
    budget: 'budget'
};

export const Production_Type = {
    developmentProposal: 'Development Proposal'
};

export const updateProductionTemplateValues = (production: Production, isStepperTemplate: boolean) => {
    const l10n = getL10nDef();
    const {
        productionType,
        productionStatus,
        // projectOwner,
        budget
    } = production;
    // const owner = projectOwner || getDefaultOwner(userProfile)
    let updatedValues = {};
    const commonValuesToBeUpdated = {
        ...cloneDeep(EMPTY_PRODUCTION_DATES),
        productionType: productionType,
        productionStatus,
        managedProduction: true,
        budget: {
            currencyCode: budget?.currencyCode || l10n.defaultCurrency,
            nanos: budget?.nanos,
            units: budget?.units
        }
    };
    if (isStepperTemplate) {
        updatedValues = { ...commonValuesToBeUpdated, projectStatus: production.projectStatus, summary: { about: '' }, };
    } else {
        updatedValues = {
            ...commonValuesToBeUpdated,
            genre: production.genre,
            locations: cloneDeep(production.locations) as LocationInput[],
            // productionDates: p.productionDates, uncomment this if we have form on the ui for creating production template
            productionGroup: production.productionGroup,
            summary: { about: production.about },
            showAttributes: production.showAttributes
            // projectOwner : owner
        };
    }
    return updatedValues;
};

export type PersonModalStateType = {
    open: boolean;
    prepopulate: {
        name: {
            firstName: string;
            lastName: string;
        };
    };
};

export type ProductionFormModalType = {
    personModalState: PersonModalStateType;
    setIsSaveDisabled: Dispatch<SetStateAction<boolean>>;
    guestEditorRef: MutableRefObject<any>;
    enableConvertingToManaged: boolean;
    onCreateOwner: (e: any) => void;
    isDialogOpen: boolean;
    setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
    setProductionId: Dispatch<SetStateAction<string>>;
    tempProd: ReferenceInput;
    hiddenFields?: Record<string, boolean>;
};