import React, { createContext, useContext, useState, ReactNode, useRef, useEffect } from 'react';
import { StepsIndexConstant } from './Stepper';

interface StepperContextProps {
    activeStep: number;
    goToNextStep: () => void;
    goToPreviousStep: () => void;
    setStepCompletion: (step: number, isValid: boolean) => void;
    checkIsStepComplete: (step: number) => boolean;
    setStepData: (step: number, data: any) => void;
    getStepData: (step: number) => any;
    setIsSaveLoading: (isLoading: boolean) => void;
    getIsSaveLoading: () => boolean;
    setTemplateData: (step: number, data: any) => void;
    getTemplateData: (step: number) => Array<any>;
}

const StepperContext = createContext<StepperContextProps | null>(null);

export const StepperProvider = ({ children }: { children: ReactNode }) => {
    const [activeStep, setActiveStep] = useState(StepsIndexConstant.zero);
    const [isStepComplete, setIsStepComplete] = useState<Record<number, boolean>>({});
    const [stepData, setStepDataState] = useState<Record<number, any>>({});
    const [templateData, setStepTemplateData] = useState<any>({});
    const [isStepperSaveLoading, setIsStepperSaveLoading] = useState(false);

    const setStepData = (step: number, data: any) => {
        setStepDataState((prev) => ({ ...prev, [step]: data }));
    };

    const getStepData = (step: number) => {
        return stepData[step] || null;
    };

    const getTemplateData = (step: number) => {
        return templateData[step] || null;
    };

    const setTemplateData = (step: number, data: any) => {
        setStepTemplateData((prev) => ({ ...prev, [step]: data }));
    };

    const goToNextStep = () => setActiveStep((prev) => prev + 1);
    const goToPreviousStep = () => setActiveStep((prev) => prev - 1);

    const setStepCompletion = (step: number, isValid: boolean) => {
        setIsStepComplete((prev) => ({ ...prev, [step]: isValid }));
    };

    const checkIsStepComplete = (step: number) => isStepComplete[step] ?? true;

    const setIsSaveLoading = (isLoading: boolean) => setIsStepperSaveLoading(isLoading);

    const getIsSaveLoading = () => isStepperSaveLoading;

    return (
        <StepperContext.Provider
            value={{
                activeStep,
                goToNextStep,
                goToPreviousStep,
                setStepCompletion,
                checkIsStepComplete,
                setStepData,
                getStepData,
                setIsSaveLoading,
                getIsSaveLoading,
                setTemplateData,
                getTemplateData
            }}
        >
            {children}
        </StepperContext.Provider>
    );
};

export const useStepper = () => {
    const context = useContext(StepperContext);
    if (!context) throw new Error('useStepper must be used within a StepperProvider');
    return context;
};
