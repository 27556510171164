import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { constants } from '../constants';
import I18n from '../i18n/I18n';
import useHistory from '../utils/useHistory';
import { UserContext } from './UserContext';
import { signOut } from './api';

export default () => {
    const [showWarning, setShowWarning] = useState(false);
    const { isAuthenticated, userProfile } = useContext(UserContext);
    const { logOut, searchParams } = useHistory();

    const inactivityTimeout = useMemo(() => {
        return searchParams.has('inactivityTimeout')
            ? parseInt(searchParams.get('inactivityTimeout'))
            : constants.inactivityTimeout;
    }, [searchParams]);

    const inactivityDialogTimeout = useMemo(() => {
        return searchParams.has('inactivityDialogTimeout')
            ? parseInt(searchParams.get('inactivityDialogTimeout'))
            : constants.inactivityDialogTimeout;
    }, [searchParams]);

    useEffect(() => {
        console.log('InactivityMonitor', 'timeout:', inactivityTimeout, 'dialog:', inactivityDialogTimeout);
        let lastActivity = Date.now();

        const interval = setInterval(() => {
            if (isAuthenticated) {
                const diff = Math.round((Date.now() - lastActivity) / 1000);
                if (searchParams.has('inactivityTimeout') || searchParams.has('inactivityDialogTimeout')) {
                    console.log('InactivityMonitor counter:', diff);
                }
                if (diff > inactivityTimeout) {
                    signOut(userProfile.username).finally(logOut);
                } else if (!showWarning && diff + inactivityDialogTimeout > inactivityTimeout) {
                    setShowWarning(true);
                }
            }
        }, 1000);

        const restartTimer = debounce(() => {
            lastActivity = Date.now();
        }, 100);

        window.addEventListener('load', restartTimer);
        document.addEventListener('mousemove', restartTimer);
        document.addEventListener('mousedown', restartTimer);
        document.addEventListener('keydown', restartTimer);
        document.addEventListener('wheel', restartTimer);
        document.addEventListener('touchstart', restartTimer);
        return () => {
            clearInterval(interval);
            window.removeEventListener('load', restartTimer);
            document.removeEventListener('mousemove', restartTimer);
            document.removeEventListener('mousedown', restartTimer);
            document.removeEventListener('keydown', restartTimer);
            document.removeEventListener('wheel', restartTimer);
            document.removeEventListener('touchstart', restartTimer);
        };
    }, [inactivityDialogTimeout, inactivityTimeout, isAuthenticated]);

    return (
        <Dialog open={showWarning} keepMounted>
            <DialogContent>
                <DialogContentText>
                    <I18n token="session.timeout.warning" />
                </DialogContentText>
                <DialogActions>
                    <Button onClick={() => setShowWarning(false)}>
                        <I18n token="session.timeout.button" />
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
