import React, { useContext, useEffect } from 'react';
import { useStepper } from '../../../common/components/StepperContext';
import { StepsIndexConstant } from '../../../common/components/Stepper';
import { FormContext } from '../../../common/form/FormContext';
import ProductionFormModal from '../../../production/form/CreateProductionFormModal';
import { ProductionFormModalType, Production_Field_Names } from '../../../production/helpers/productionUtils';
import { isEqual } from 'lodash';

export default (props: ProductionFormModalType) => {
    const { getStepData, setStepCompletion, setStepData } = useStepper();
    const { state, setState, validation } = useContext(FormContext);

    useEffect(() => {
        const stepVal = getStepData(StepsIndexConstant.one);
        if (stepVal) {
            setState({ ...stepVal });
        }
    }, []);

    useEffect(() => {
        setStepCompletion(StepsIndexConstant.one, validation?.isValid);
    }, [validation?.isValid]);

    useEffect(() => {
        const currentData = getStepData(StepsIndexConstant.one);
        if (validation?.isValid && !isEqual(currentData, state)) setStepData(StepsIndexConstant.one, state);
    }, [validation?.isValid, state]);

    const keysToHide = [
        'productionStatus',
        'locations',
        'scheduleSection',
        'productionTrack',
        'sectionTitle',
        'productionGroup.productionReference',
        'showAttributes',
        'budget.currencyCode',
        'budget'
    ];
    const hiddenFields = keysToHide.reduce(
        (acc, key) => {
            if (Production_Field_Names[key]) {
                acc[Production_Field_Names[key]] = true;
            }
            return acc;
        },
        {} as Record<string, boolean>
    );

    return <ProductionFormModal {...props} hiddenFields={hiddenFields} />;
};
