import React, { useState, useMemo } from 'react';
import Stepper, { StepsIndexConstant } from '../../common/components/Stepper';
import DisplayElement from '../../common/components/DisplayElement';
import { FormProvider } from '../../common/form/FormContext';
import LabNameComponent from './LabNameComponent';
import { StepperProvider, useStepper } from '../../common/components/StepperContext';
import { ValidationRulesType } from '../../production/helpers/productionUtils';
import Validator from '../../common/form/Validator';

const DisplayComponent = ({ label, value }: { label: string; value: string }) => {
    return <DisplayElement label={label} value={value} />;
};

const EMPTY_NAME = { firstName: '', lastName: '' };

const NameComponent = () => {
    const { getStepData } = useStepper();
    const stepData = getStepData(StepsIndexConstant.zero);
    const defaultStateVal = stepData ? { ...stepData } : { ...EMPTY_NAME };
    const [values, setValues] = useState({ ...defaultStateVal });

    const validationRules: ValidationRulesType = useMemo(() => {
        return {
            firstName: [Validator.RULES.isRequired]
        };
    }, [values]);

    return (
        <FormProvider state={values} setState={setValues} validationRules={validationRules}>
            <LabNameComponent />
        </FormProvider>
    );
};

export default () => {
    const steps = [
        {
            label: 'Form Component',
            component: ({}) => <NameComponent />
        },
        {
            label: 'Display Component',
            component: () => <DisplayComponent label="Demo Label" value="Demo Value" />
        }
    ];

    const onSave = (data: any) => {
        return new Promise((resolve, reject) => {
            console.log('each step data to be saved', data);
        });
    };

    return (
        <StepperProvider>
            <Stepper steps={steps} onSave={onSave} />
        </StepperProvider>
    );
};
