import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { authLink } from '../auth/api';
import { UserContext } from '../auth/UserContext';
import { constants } from '../constants';
import { searchClient, slimResultsQuery } from '../list/slimQuery';

import { NoteInput } from 'sr-types/lib/note/v1/graphql';
import { useMutationWithContext } from '../hooks/useMutationWithContext';

export const client = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/note/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const splitNote = (text) => {
    const lines = text.split('\n');
    const first = lines.shift();
    const rest = lines.join('\n');
    return { first, rest };
};

export const saveNote: DocumentNode = gql`
    mutation SaveNote($input: NoteInput!) {
        saveNote(input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const removeNote: DocumentNode = gql`
    mutation DeleteNote($id: ID!) {
        deleteNote(id: $id) {
            id
            errors {
                field
                message
            }
        }
    }
`;

export const useGetNote = (linkedEntityId: string, skip: boolean = undefined) => {
    const { activeOrganizationAccount } = useContext(UserContext);

    const {
        data: noteData,
        loading: isNoteLoading,
        error: noteFetchError,
        refetch: noteRefetch
    } = useQuery(slimResultsQuery('Note'), {
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        variables: {
            filters: [
                {
                    identifier: 'entity',
                    value: 'Note'
                },
                {
                    identifier: 'entityId',
                    value: linkedEntityId
                }
            ],
            page: {
                from: 0,
                size: 100
            }
        },
        client: searchClient,
        fetchPolicy: constants.apolloFetchPolicy,
        notifyOnNetworkStatusChange: true,
        skip: skip
    });

    return { noteData, isNoteLoading, noteFetchError, noteRefetch };
};

export const useSaveNote = () => {
    const [saveMutation, { loading: isNoteSaving }] = useMutationWithContext(saveNote, client);

    const saveNewNote = ({ input }: { input: NoteInput }) => {
        return new Promise<void>((resolve, reject) => {
            saveMutation({
                variables: {
                    input
                }
            })
                .then((res) => {
                    res.data.saveNote.errors ? reject(res.data.saveNote.errors) : resolve(res.data.saveNote.id);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    return { saveNewNote, isNoteSaving };
};
