import { Autocomplete, createFilterOptions, ListItemText, TextField, TextFieldVariants } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { constants } from '../constants';
import { UserContext } from '../auth/UserContext';
import ChipOptionsRenderer, { RendererType } from '../form/widgets/ChipOptionsRenderer';
import { debounce } from 'lodash';
import { DocumentNode } from '@apollo/client';
import { isReferenceOptionEqualToValue, optionToReference, ReferenceOption, referenceToOption } from '../form/forms';
import { searchClient, slimSearchQuery } from '../list/slimQuery';

type ReferenceAutocompleteProps = {
    id?: string;
    name: string;
    entity: string;
    inputLabel: string;
    value: any;
    onChange: (name, value) => void;
    additionalFragmentName?: string;
    multiple?: boolean;
    disabled: boolean;
    required: boolean;
    pageSize?: number;
    hasErrors: boolean;
    errorText: string;
    filter?: any;
    allowFreeForm?: boolean;
    variant?: TextFieldVariants;
    size?: 'small' | 'medium';
    type?: 'autocomplete' | 'chips' | 'radio';
    OptionRenderer?: ({ option }) => JSX.Element;
    getOption?: (item) => void;
    onCreateHandle?: (value, save) => Promise<any>;
    addOption?: boolean;
    query?: DocumentNode;
    saveMutation?: Promise<any>;
    preload?: boolean;
    filterById?: string[];
    defaultValueIds?: string[];
    setFirstAsDefault?: boolean;
    sortBy?: string;
    disableIfOptionUnavailable?: boolean;
    allowDuplicateValue?: boolean;
    notOnlyRef?: boolean;
    testId?: string;
};

const filterOptions = createFilterOptions<ReferenceOption>();

const markMatch = (option, term) => {
    const matchLabel = option.label.toUpperCase().indexOf(term.toUpperCase());
    const matchSubLabel = option.subLabel?.toUpperCase().indexOf(term.toUpperCase());

    // If there is no match return the unchanged text.
    if (matchLabel < 0 && (matchSubLabel == undefined || matchSubLabel < 0)) {
        return option.label;
    }

    const match = matchLabel >= 0 ? matchLabel : matchSubLabel;
    const text = matchLabel >= 0 ? option.label : option.subLabel;

    const pre = text.substring(0, match);
    const mid = text.substring(match, match + term.length);
    const post = text.substring(match + term.length);

    return (
        <>
            {pre}
            <b>{mid}</b>
            {post}
        </>
    );
};

const getValue = (multiple, value, notOnlyRef) => {
    if (multiple && Array.isArray(value)) {
        return value.map((item) => (!notOnlyRef ? referenceToOption(item) : item));
    } else {
        return value && value.label ? referenceToOption(value) : '';
    }
};

const setDefaultValueById = (
    defaultValueIds: string[],
    multiple: boolean,
    options: ReferenceOption[],
    handleChange: (value) => void
) => {
    if (multiple) {
        const filteredOptions = options.filter((option) => defaultValueIds.includes(option.id));
        handleChange(filteredOptions);
    } else {
        const findValue = options.find((option) => defaultValueIds.includes(option.id));
        handleChange(findValue);
    }
};

export default (props: ReferenceAutocompleteProps) => {
    const {
        id,
        name,
        entity,
        inputLabel,
        value,
        onChange,
        additionalFragmentName = undefined,
        multiple = false,
        disabled = false,
        required = false,
        hasErrors = undefined,
        errorText = undefined,
        filter = undefined,
        allowFreeForm = false,
        variant = constants.defaultWidgetVariant,
        size = constants.defaultWidgetSize,
        type = 'autocomplete',
        OptionRenderer = undefined,
        getOption = undefined,
        onCreateHandle = undefined,
        addOption = false,
        query = slimSearchQuery(entity, false, additionalFragmentName),
        saveMutation = undefined,
        preload = true,
        filterById = [],
        defaultValueIds = [],
        setFirstAsDefault = false,
        sortBy,
        disableIfOptionUnavailable = false,
        allowDuplicateValue = false,
        notOnlyRef = false,
        testId = undefined
    } = props;

    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = useState<ReferenceOption[]>([]);
    const [loading, setLoading] = useState(false);
    const { activeOrganizationAccount } = useContext(UserContext);
    const intialMount = useRef(true);
    const [disableForNoOption, setDisableForNoOption] = useState(false);

    const updateOptions = (term, force = false, filter) => {
        if (force || !preload) {
            setLoading(true);
            searchClient
                .query({
                    query: query,
                    variables: {
                        entity,
                        query: term,
                        page: { from: 0, size: 50 },
                        filter,
                        sortBy
                    },
                    fetchPolicy: constants.apolloFetchPolicy,
                    context: {
                        headers: {
                            ownerId: activeOrganizationAccount
                        }
                    }
                })
                .then((res) => {
                    if (res && res.data.search.hits && res.data.search.hits.items) {
                        const items = res.data.search.hits.items;
                        if (items) {
                            const opts = items.map((item) => {
                                return getOption
                                    ? getOption(item)
                                    : {
                                          id: item.id,
                                          label: item.name,
                                          ref: item.reference
                                      };
                            });
                            setOptions(opts);
                            disableIfOptionUnavailable && !items.length && setDisableForNoOption(true);
                            if (opts && opts.length === 1 && setFirstAsDefault && intialMount.current) {
                                setDefaultValueById(
                                    opts.map((op) => op.id),
                                    multiple,
                                    opts,
                                    handleChange
                                );
                                intialMount.current = false;
                            }
                            if (defaultValueIds.length) {
                                setDefaultValueById(defaultValueIds, multiple, opts, handleChange);
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.error('ReferenceAutocomplete load error:', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const debouncedFunction = useCallback(
        debounce((term, filter) => updateOptions(term, false, filter), 500),
        []
    );

    useEffect(() => {
        debouncedFunction(inputValue, filter);
    }, [debouncedFunction, inputValue, filter]);

    // Only first time
    useEffect(() => {
        if (preload) {
            updateOptions('', true, filter);
        }
    }, []);

    const val = useMemo(() => {
        return getValue(multiple, value, notOnlyRef);
    }, [multiple, value]);

    const handleChange = (value: ReferenceOption | ReferenceOption[]) => {
        onChange(name, value ? optionToReference(value, multiple, notOnlyRef) : multiple ? [] : undefined);
    };

    if (type === 'chips') {
        return (
            <ChipOptionsRenderer
                loading={loading}
                options={options}
                value={value}
                label={inputLabel}
                isOptionEqualToValue={isReferenceOptionEqualToValue}
                onChange={handleChange}
            />
        );
    } else if (type === 'radio') {
        return (
            <ChipOptionsRenderer
                rendererType={RendererType.RADIO}
                loading={loading}
                options={options}
                value={value}
                label={inputLabel}
                isOptionEqualToValue={isReferenceOptionEqualToValue}
                onChange={handleChange}
            />
        );
    } else {
        return (
            <Autocomplete
                data-testid={testId}
                size={size}
                disabled={disabled || disableForNoOption}
                id={id}
                multiple={multiple}
                fullWidth
                autoComplete={false}
                autoSelect={allowFreeForm}
                autoHighlight={true}
                freeSolo={true}
                filterSelectedOptions={false}
                limitTags={2}
                loading={loading}
                options={options}
                clearOnBlur={!multiple}
                onOpen={() => {
                    if (!options.length) {
                        updateOptions('', preload, filter);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered: ReferenceOption[] =
                        filterById && Array.isArray(filterById) && filterById.length
                            ? filterOptions(options, params).filter((option) => !filterById.includes(option.id))
                            : filterOptions(options, params);

                    const { inputValue } = params;
                    const isExisting = options.some(
                        (option) =>
                            inputValue &&
                            inputValue !== '' &&
                            (inputValue === option.label || inputValue === option.subLabel)
                    );

                    // Allow free form text in this field? Add this free form text as a first option.
                    if (allowFreeForm && inputValue && (!isExisting || allowDuplicateValue)) {
                        filtered.unshift({
                            id: '',
                            label: inputValue,
                            ref: {
                                id: '',
                                label: inputValue
                            }
                        });
                    }

                    // Suggest the creation of a new value.
                    if (addOption && inputValue !== '' && !isExisting) {
                        filtered.push({
                            id: '',
                            label: inputValue,
                            ref: {
                                id: '',
                                label: inputValue
                            },
                            createOption: true
                        });
                    }

                    return filtered;
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option: ReferenceOption) => {
                    if (option && option.label && option.subLabel) {
                        return option.label + ' ' + option.subLabel;
                    }
                    return option && option.label ? option.label : '';
                }}
                renderOption={(props, option) => {
                    const label = OptionRenderer
                        ? option.label
                        : option.createOption
                          ? `Add ${option.label}`
                          : markMatch(option, inputValue);
                    const subLabel = option.subLabel;
                    return (
                        <li {...props} key={option.id}>
                            {OptionRenderer ? (
                                <OptionRenderer option={option} />
                            ) : (
                                <ListItemText primary={label} secondary={subLabel} />
                            )}
                        </li>
                    );
                }}
                value={val}
                isOptionEqualToValue={isReferenceOptionEqualToValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={disabled || disableForNoOption}
                        required={required}
                        variant={variant}
                        label={inputLabel}
                        error={hasErrors}
                        helperText={errorText}
                        onKeyDown={(e) => {
                            if (!multiple && (e.key === 'Backspace' || e.key === 'Delete')) {
                                onChange(name, undefined);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            size: size
                        }}
                    />
                )}
                onChange={(e, newValue) => {
                    if (addOption && newValue?.createOption) {
                        const promise = onCreateHandle(newValue, saveMutation);
                        if (promise && typeof promise.then === 'function') {
                            promise.then((createdValue) => {
                                setOptions([...options, createdValue]);
                                handleChange(createdValue);
                            });
                        }
                    } else if (addOption && Array.isArray(newValue) && newValue?.length) {
                        newValue.forEach((val, index) => {
                            if (val.createOption) {
                                const promise = onCreateHandle(newValue[index], saveMutation);
                                if (promise && typeof promise.then === 'function') {
                                    promise.then((createdValue) => {
                                        setOptions([...options, createdValue]);
                                        newValue.pop(); //remove manually created one without id and ref for the createOption from values and use the createdValue from response
                                        handleChange([...newValue, createdValue]);
                                    });
                                }
                            } else {
                                handleChange(newValue);
                            }
                        });
                    } else if (allowFreeForm && typeof newValue === 'string') {
                        // TODO We could start omitting empty strings?
                        handleChange({
                            id: '',
                            label: newValue,
                            ref: {
                                id: '',
                                label: newValue,
                                uriPrefix: ''
                            }
                        });
                    } else {
                        handleChange(newValue);
                    }
                }}
            />
        );
    }
};
