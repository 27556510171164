import React, { useContext, useEffect } from 'react';
import { FormContext } from '../../../common/form/FormContext';
import { PersonInviteFormProps } from '../../../shortlists/form/inquiry/inquiry';
import { useStepper } from '../../../common/components/StepperContext';
import { StepsIndexConstant } from '../../../common/components/Stepper';
import PersonInviteForm from '../../../shortlists/form/PersonInviteForm';
import { isEqual } from 'lodash';

export default (props: PersonInviteFormProps) => {
    const { state, validation } = useContext(FormContext);
    const { getStepData, setStepCompletion, setStepData } = useStepper();

    useEffect(() => {
        const currentData = getStepData(StepsIndexConstant.two);
        if (validation?.isValid && !isEqual(currentData, state)) {
            setStepData(StepsIndexConstant.two, state);
        }
    }, [state, validation?.isValid]);

    useEffect(() => {
        setStepCompletion(StepsIndexConstant.two, validation?.isValid);
    }, [validation?.isValid]);

    return <PersonInviteForm {...props} />;
};
