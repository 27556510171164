export const LINKED_USER_ERROR = 'LinkedUsers';
export const USER_EXISTS_ERROR = 'Already found an entry for username';
export const NEW_USER_SIGNUP = 'NewAccountSignup';
export const CURRENT_IDP_IDENTIFIER = 'currentIdpIdentifier';
export const CODE_CHALLENGE = 'codeChallenge';
export const CODE_VERIFIER = 'codeVerifier';

// @ts-ignore
const authorizeEndpoint = window._env_.AUTHORIZE_ENDPOINT;
// @ts-ignore
const tokenEndpoint = window._env_.TOKEN_ENDPOINT;
// @ts-ignore
const clientId = window._env_.CLIENT_ID;
// @ts-ignore
const clientSecret = window._env_.CLIENT_SECRET;
// @ts-ignore
const redirectUri = window._env_.BASE_URI + '/login';

export const buildSSORedirectURI = (idpIdentifier, codeChallenge, username) => {
    const ssoURI = new URL(authorizeEndpoint);
    ssoURI.searchParams.append('response_type', 'code');
    ssoURI.searchParams.append('identity_provider', idpIdentifier);
    ssoURI.searchParams.append('client_id', clientId);
    ssoURI.searchParams.append('redirect_uri', redirectUri);
    ssoURI.searchParams.append('scope', 'openid+profile');
    ssoURI.searchParams.append('code_challenge_method', 'S256');
    ssoURI.searchParams.append('code_challenge', codeChallenge);
    ssoURI.searchParams.append('login_hint', username);
    return decodeURIComponent(ssoURI.href);
};

const buildTokenURI = (code, codeVerifier) => {
    const tokenURI = new URL(tokenEndpoint);
    tokenURI.searchParams.append('grant_type', 'authorization_code');
    tokenURI.searchParams.append('client_id', clientId);
    tokenURI.searchParams.append('redirect_uri', redirectUri);
    tokenURI.searchParams.append('scope', 'openid+profile');
    tokenURI.searchParams.append('code_verifier', codeVerifier);
    tokenURI.searchParams.append('code', code);
    return decodeURIComponent(tokenURI.href);
};

export const buildGoogleSocialSigninURI = () => {
    const ssoURI = new URL(authorizeEndpoint);
    ssoURI.searchParams.append('response_type', 'code');
    ssoURI.searchParams.append('identity_provider', "Google");
    ssoURI.searchParams.append('client_id', clientId);
    ssoURI.searchParams.append('redirect_uri', redirectUri);
    ssoURI.searchParams.append('scope', 'openid+profile');
    return decodeURIComponent(ssoURI.href);
}

export function getTokensFromAuthCode(code: string, codeVerifier: string) {
    return new Promise((resolve, reject) => {
        fetch(buildTokenURI(code, codeVerifier), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                Authorization: 'Basic ' + btoa(`${clientId}:${clientSecret}`)
            }
        })
            .then((res) => {
                return res.json().then((data) => {
                    res.ok ? resolve(data) : reject(data);
                });
            })
            .catch((err) => {
                return reject(err);
            });
    });
}
