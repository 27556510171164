import {
    AppBar,
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Toolbar,
    Tooltip
} from '@mui/material';
import Menu from '@mui/material/Menu';
import React, { useContext, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useStyling } from '../Theme';
import { UserContext } from '../auth/UserContext';
import { AllPermissions, getFeaturePermissions, signOut } from '../auth/api';
import { Icons } from '../icons/Icons';
import { isDebugAtom } from '../utils/DevelAtoms';
import useHistory from '../utils/useHistory';
import { isDrawerOpenAtom } from './AppDrawerAtoms';
import AppSelectionMenu from './AppSelectionMenu';
import Branding from './Branding';
import UserAvatar from './UserAvatar';
import { AppDefinition, PROFILES } from './apps';

const personalMenuItems = (userProfile, avatar, onMenuItemClick) => {
    const memberships = userProfile.memberships.filter((m) => m.personal);
    if (memberships && memberships.length === 1) {
        const membership = memberships[0];
        return [
            <MenuItem key={`personal-name`}>
                {avatar}
                <ListItemText sx={{ pl: '12px' }}>{membership.name}</ListItemText>
            </MenuItem>,
            <MenuItem
                key={`personal-profile-${membership.id}`}
                onClick={() => {
                    onMenuItemClick(membership.id, PROFILES, `${PROFILES.home}`);
                }}
            >
                <ListItemText inset>Profile</ListItemText>
            </MenuItem>,
            <MenuItem
                key={`personal-settings-${membership.id}`}
                onClick={() => {
                    onMenuItemClick(membership.id, PROFILES, `${PROFILES.home}/settings`);
                }}
            >
                <ListItemText inset>Settings</ListItemText>
            </MenuItem>
        ];
    } else {
        return [<React.Fragment />];
    }
};

const shortlistsMenuItem = (onItemClick) => {
    return [
        <Divider key="shortlists-divider" />,
        <MenuItem key="shortlists" onClick={onItemClick} disabled>
            <ListItemIcon>
                <Icons.CollectionBookmarks fontSize="small" />
            </ListItemIcon>
            <ListItemText>My shortlists</ListItemText>
        </MenuItem>
    ];
};

const logoutMenuItem = (onItemClick) => {
    return [
        <Divider key="logout-divider" />,
        <MenuItem key="logout" onClick={onItemClick}>
            <ListItemIcon>
                <Icons.Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sign out</ListItemText>
        </MenuItem>
    ];
};

export default () => {
    const { application, setApplication, activeOrganizationAccount, setActiveOrganizationAccount, userProfile } =
        useContext(UserContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [isDebug, setIsDebug] = useRecoilState(isDebugAtom);
    const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(isDrawerOpenAtom);
    const labPerms = getFeaturePermissions(userProfile, application, activeOrganizationAccount, 'Lab');
    const isLabs = labPerms[AllPermissions.Read];
    const { changeRoute, logOut } = useHistory();
    const { isDarkMode, setIsDarkMode } = useStyling();

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'nav-app-menu';
    const mobileMenuId = 'nav-app-menu-mobile';

    const menuItems = [];

    const onMenuItemClick = (newOrgId: string, app: AppDefinition, pathOverride: string) => {
        setActiveOrganizationAccount(newOrgId).then(() => {
            setApplication(app);
            changeRoute(pathOverride || app.home, { orgId: newOrgId });
            handleMenuClose();
        });
    };

    if (userProfile && userProfile.memberships && userProfile.memberships.length > 1) {
        menuItems.push(...personalMenuItems(userProfile, <UserAvatar />, onMenuItemClick));
    }
    menuItems.push(
        ...shortlistsMenuItem(() => {
            changeRoute('/where/does/this/go?');
        })
    );

    menuItems.push(
        <MenuItem
            key={'isDarkMode'}
            onClick={() => {
                setIsDarkMode(!isDarkMode);
            }}
        >
            <ListItemIcon>
                {isDarkMode ? <Icons.LightMode fontSize="small" /> : <Icons.DarkMode fontSize="small" />}
            </ListItemIcon>
            <ListItemText>{isDarkMode ? 'Light mode' : 'Dark mode'}</ListItemText>
        </MenuItem>
    );

    if (isLabs) {
        // const debug = window.location.search.indexOf('debug=true') > -1;
        menuItems.push(
            <MenuItem
                key={'isDebug'}
                onClick={() => {
                    const newIsDebug: boolean = !isDebug;
                    setIsDebug(newIsDebug);
                    localStorage.setItem('isDebug', newIsDebug ? 'true' : 'false');
                    handleMenuClose();
                }}
            >
                <ListItemIcon>
                    <Icons.Science fontSize="small" />
                </ListItemIcon>
                <ListItemText>{isDebug ? 'Development off' : 'Development on'}</ListItemText>
            </MenuItem>
        );
    }

    menuItems.push(
        logoutMenuItem(() => {
            signOut(userProfile.username).then(() => {
                handleMenuClose();
                logOut(false);
            });
        })
    );

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {menuItems}
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {menuItems}
        </Menu>
    );

    return (
        <>
            <AppBar position={'static'}>
                <Toolbar sx={{ paddingLeft: '20px !important', paddingRight: '20px !important' }}>
                    {activeOrganizationAccount && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 1 }}
                            onClick={() => {
                                if (activeOrganizationAccount) {
                                    setIsDrawerOpen(!isDrawerOpen);
                                }
                            }}
                        >
                            <Icons.Menu />
                        </IconButton>
                    )}

                    <Branding />
                    {activeOrganizationAccount && (
                        <>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: 'flex' }}>
                                <IconButton
                                    sx={{ display: { xs: 'none', md: 'flex' }, width: '48px' }}
                                    size={'large'}
                                    color="inherit"
                                >
                                    <Icons.Help />
                                </IconButton>
                                <IconButton
                                    sx={{ display: { xs: 'none', md: 'flex' }, width: '48px' }}
                                    size={'large'}
                                    color="inherit"
                                    // @ts-ignore
                                    onClick={() => window.showCollectorDialog?.()}
                                >
                                    <Tooltip title={'Send Feedback'}>
                                        <Icons.Feedback fontSize={'small'} />
                                    </Tooltip>
                                </IconButton>
                                <AppSelectionMenu
                                    selectedApp={application ? application.id : undefined}
                                    setSelectedApp={(selectedApp: AppDefinition) => {
                                        setApplication(selectedApp);
                                        changeRoute(selectedApp.home);
                                    }}
                                />
                                <IconButton
                                    sx={{ display: { xs: 'none', md: 'flex' }, width: '48px' }}
                                    size={'large'}
                                    edge={'end'}
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <UserAvatar />
                                </IconButton>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', md: 'none' }, width: { xs: '24px', md: '0px' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <Icons.MoreVert />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {activeOrganizationAccount && renderMobileMenu}
            {activeOrganizationAccount && renderMenu}
        </>
    );
};
