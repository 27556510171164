import { useMutation } from '@apollo/client';
import _, { cloneDeep, find, findIndex, isEmpty } from 'lodash';
import React, { ReactElement, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { EMPTY_PRODUCTION_DATES, ProductionWithReference, ValidationRulesType } from '../helpers/productionUtils';
import { UserContext } from '../../common/auth/UserContext';
import { FormProvider } from '../../common/form/FormContext';
import { enqueueSnackbar } from '../../common/components/Toast';
import I18n from '../../common/i18n/I18n';
import { client, saveQuery, toAssociatedOrgs, toAssociatedPeople } from '../helpers/production';
import {
    EMPTY_ABOUT_INFO,
    EMPTY_ALT_IDS,
    EMPTY_ASSOCIATED_ORGS,
    EMPTY_ASSOCIATED_PPL,
    EMPTY_PROD_INFO
} from './ProductionEditor';
import { productionListeners, useLazyProductionDetailsData } from '../helpers/productionApi';
import FormModal from '../../common/form/FormModal';
import {
    ContactInput,
    LocationInput,
    Production,
    ProductionInput,
    ReferenceInput
} from 'sr-types/lib/production/v1/graphql';
import { PLANNING } from '../../common/nav/apps';
import useHistory from '../../common/utils/useHistory';
import { areAllFiltersInList } from '../../common/utils/commonUtils';
import { searchClient } from '../../common/list/slimQuery';
import { ProductionModes } from '../ProductionList';
import { getL10nDef } from '../../common/i18n/localization';
import { extractNames } from '../helpers/peopleUtils';
import { EMPTY_PERSON_MODAL_STATE } from '../../shortlists/ShortlistLineItemEditor';
import { doSavePerson } from './production/AddProjectOwnerModal';
import CreateProductionForUser from '../../profile/home/hireResource/CreateProductionForUser';
import ProductionFormModal from './CreateProductionFormModal';

type ProductionCreateFormProps = {
    title?: string | ReactElement;
    clone?: boolean;
    productionId?: string;
    isOpen?: boolean;
    onCancel?: () => void;
    onSave?: (productionId: string) => void;
    enableConvertingToManaged?: boolean;
    isInModal?: boolean;
};

export default (props: ProductionCreateFormProps) => {
    const {
        title,
        clone,
        productionId: prodIdProps,
        isOpen = true,
        onCancel,
        onSave,
        enableConvertingToManaged = false,
        isInModal = true
    } = props;
    const [productionId, setProductionId] = useState(prodIdProps);
    const [tempProd, setTempProd] = useState<ReferenceInput>({ id: '', label: '' });
    const { activeOrganizationAccount, application, userProfile } = useContext(UserContext);

    const { getProductionDetailsData, productionDetailsData, productionDetailsLoading } =
        useLazyProductionDetailsData(productionId);

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [values, setValues] = useState<ProductionWithReference>({
        headline: '',
        otherTitles: [{ key: 'AKA', value: '' }],
        ...cloneDeep(EMPTY_PROD_INFO),
        ...cloneDeep(EMPTY_ABOUT_INFO),
        ...cloneDeep(EMPTY_ALT_IDS),
        ...cloneDeep(EMPTY_ASSOCIATED_PPL),
        ...cloneDeep(EMPTY_ASSOCIATED_ORGS),
        ...cloneDeep(EMPTY_PRODUCTION_DATES),
        // projectOwner: getDefaultOwner(userProfile), /* We may use it later on */
        name: undefined
    });
    const { searchParams } = useHistory();
    const productionMode = searchParams.get('productionMode');
    const l10n = getL10nDef();
    const guestEditorRef = useRef(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isFormSaving, setIsFormSaving] = useState(false);
    const [personModalState, setPersonModalState] = useState(EMPTY_PERSON_MODAL_STATE);

    useLayoutEffect(() => {
        if (prodIdProps) {
            getProductionDetailsData();
        }
    }, []);

    useEffect(() => {
        if (productionId) {
            getProductionDetailsData({ variables: { id: productionId } });
        }
    }, [productionId]);

    const [save, { loading: isSaving }] = useMutation(saveQuery, {
        client: client,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });

    const validationRules: ValidationRulesType = useMemo(() => {
        const otherTitles = find(values?.['otherTitles'], { key: 'AKA' });
        return {
            [enableConvertingToManaged ? 'name' : 'name.label']:
                otherTitles && otherTitles.value != ''
                    ? []
                    : [
                          {
                              isValid: (prop, state, isModified, resolveI18nToken) => {
                                  const value = _.get(state, prop);

                                  const valid = enableConvertingToManaged ? !!value?.label : !!value;
                                  return valid ? true : resolveI18nToken('validation.value.required');
                              },
                              isRequired: true
                          }
                      ],
            otherTitles: values?.name?.label
                ? []
                : [
                      {
                          isValid: (prop, state, isModified, resolveI18nToken) => {
                              const value = _.get(state, prop);
                              const otherTitles = find(value, { key: 'AKA' });
                              const valid = !!otherTitles?.value;
                              return valid ? true : resolveI18nToken('validation.value.required');
                          },
                          isRequired: true
                      }
                  ]
        };
    }, [values]);

    useEffect(() => {
        if (values?.name?.id && values?.name?.label) {
            setIsDialogOpen(true);
            setTempProd({ id: values.name.id, label: values.name.label });
        }
    }, [values?.name, values?.name?.id]);

    useEffect(() => {
        if (productionDetailsData && productionDetailsData.production) {
            let associatedOrganizations = [];
            productionDetailsData.production.associatedOrgs?.map((orgs) => {
                associatedOrganizations.push({
                    organizationReference: orgs.organizationReference,
                    organization: orgs.name,
                    productionRole: orgs.productionRole,
                    organizationTypes: orgs.organizationTypes,
                    organizationCategories: orgs.organizationCategories
                });
            });
            const {
                otherTitles,
                productionType,
                about,
                budget,
                productionGroup,
                genre,
                productionStatus,
                productionYears,
                name
                //projectOwner,
            } = productionDetailsData.production;
            //const owner = projectOwner || getDefaultOwner(userProfile)

            const newProd: Production = {
                ...productionDetailsData.production,
                name: { id: '', label: name },
                type: productionType || '',
                summary: {
                    about: about
                },
                budget: {
                    currencyCode: budget && budget.currencyCode ? budget.currencyCode : l10n.defaultCurrency,
                    nanos: budget && budget.nanos,
                    units: budget && budget.units ? budget.units : 0
                },
                otherTitles: otherTitles,
                productionGroup: productionGroup?.productionReference
                    ? { ...productionGroup.productionReference, label: productionGroup?.label }
                    : null,
                genres: genre || [],
                productionStatus: productionStatus,
                productionYears: productionYears,
                alternateIds: productionDetailsData.production.alternateIds || [],
                associatedOrgs: associatedOrganizations,
                guests: productionDetailsData.production.associatedPeople || [],
                productionDates: productionDetailsData?.production?.productionDates?.dateRange?.start
                    ? productionDetailsData.production.productionDates
                    : { ...EMPTY_PRODUCTION_DATES.productionDates }
                //projectOwner : owner
            };
            // TODO What needs to be reset if we're cloning another production?
            if (clone) {
                newProd.name = '';
                newProd.about = '';
                const akaIndex = findIndex(newProd.otherTitles, { key: 'AKA' });
                if (akaIndex >= 0) {
                    newProd.otherTitles[akaIndex].value = '';
                }
            }
            setValues(newProd);
        }
    }, [clone, l10n.defaultCurrency, productionDetailsData?.production?.identity?.id]);

    const doSave = () => {
        if (personModalState.open)
            doSavePerson(
                setIsFormSaving,
                (input) => {
                    setValues({ ...values, projectOwner: input });
                },
                guestEditorRef,
                setPersonModalState
            );
        else {
            const name = values.name;
            const productionGroup =
                {
                    label: values.productionGroup?.label,
                    productionReference: values?.productionGroup
                } || {};
            const savedEntity: ProductionInput = {
                publicTitle: name.label,
                summary: {
                    headline: values.headline || '',
                    about: values.about || ''
                },
                budget: {
                    currencyCode: values.budget.currencyCode || l10n.defaultCurrency,
                    nanos: values.budget.nanos || undefined,
                    units: values.budget.units || undefined
                },
                productionType: values.productionType || '',
                productionGroup,
                projectStatus: values.projectStatus || ''
                //projectOwner: values.projectOwner || undefined  /* We may use it later on */
            };
            if (productionId && !clone) {
                savedEntity.identity = productionDetailsData?.production?.identity;
                savedEntity.managedProduction = values.managedProduction;
            }
            if (
                (application.home === PLANNING.home && productionMode === ProductionModes.workspace) ||
                enableConvertingToManaged
            ) {
                savedEntity.managedProduction = true;
            }
            if (values.releaseDate) {
                savedEntity.releaseDate = values.releaseDate;
            }
            if (values.productionYears) {
                savedEntity.productionYears = values.productionYears;
            }
            if (values.otherTitles && values.otherTitles.length) {
                savedEntity['otherTitles'] = values.otherTitles.filter((item) => item.value);
            }
            if (values?.genre?.length) {
                savedEntity.genre = values.genre;
            }
            if (typeof values.productionStatus === 'string' && values.productionStatus) {
                savedEntity.productionStatus = values.productionStatus;
            }
            if (values.locations && values.locations.length) {
                const locs = values.locations.map((location) => {
                    return { ...location, primary: location.primary || false };
                });
                savedEntity.locations = locs as LocationInput[];
            }
            if (!isEmpty(values.alternateIds)) {
                savedEntity.alternateIds = values.alternateIds;
            }
            if (values.associatedOrgs && values.associatedOrgs.length) {
                savedEntity.associatedOrgs = toAssociatedOrgs(values.associatedOrgs);
            }
            if (values.associatedPeople && values.associatedPeople.length) {
                savedEntity.associatedPeople = toAssociatedPeople(values.associatedPeople);
            }
            if (values?.productionDates?.dateRange.start) {
                savedEntity.productionDates = values.productionDates;
            }
            if (values.keyPeople && values.keyPeople.length) {
                savedEntity.keyPeople = values.keyPeople as ContactInput[];
            }
            //we have to clear empty attribute values because we have validation on the backend for that.
            if (Array.isArray(values?.showAttributes) && values.showAttributes.length > 0) {
                savedEntity.showAttributes = values.showAttributes.filter((attr) => attr?.key && attr?.value);
            }
            save({
                variables: {
                    input: savedEntity
                }
            })
                .then((res) => {
                    if (res.data.saveProduction.errors) {
                        enqueueSnackbar(<I18n token="form.save.failure" values={{ name: name.label }} />, {
                            variant: 'error'
                        });
                    } else {
                        enqueueSnackbar(<I18n token="form.save.success" values={{ name: name.label }} />, {
                            variant: 'success'
                        });
                        onSave?.(res.data.saveProduction.id);
                        searchClient.refetchQueries({
                            include: ['slimResultsQuery'],
                            onQueryUpdated(observableQuery) {
                                return areAllFiltersInList(observableQuery.options.variables.filters, [
                                    {
                                        identifier: 'entity',
                                        value: 'Production'
                                    }
                                ]);
                            }
                        });
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(<I18n token="form.save.failure" values={{ name: name.label }} />, {
                        variant: 'error'
                    });
                });
        }
    };

    const onCreateOwner = (e) => {
        setPersonModalState({
            open: true,
            prepopulate: { name: extractNames(e.label) }
        });
    };

    return (
        <FormProvider
            validationRules={validationRules}
            state={values}
            setState={setValues}
            expandedSections={['production-basic-info']}
            listeners={productionListeners}
        >
            {isInModal ? (
                <FormModal
                    id={'create-production-form'}
                    title={title}
                    isOpen={isOpen}
                    isLoading={productionDetailsLoading}
                    isSaving={isSaving || isFormSaving}
                    isSaveDisabled={isSaving || isSaveDisabled}
                    expanded={true}
                    onSave={doSave}
                    onClose={onCancel}
                    sx={{ px: 1 }}
                >
                    <ProductionFormModal
                        personModalState={personModalState}
                        setIsSaveDisabled={setIsSaveDisabled}
                        guestEditorRef={guestEditorRef}
                        enableConvertingToManaged={enableConvertingToManaged}
                        onCreateOwner={onCreateOwner}
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        setProductionId={setProductionId}
                        tempProd={tempProd}
                    />
                </FormModal>
            ) : (
                <CreateProductionForUser
                    personModalState={personModalState}
                    setIsSaveDisabled={setIsSaveDisabled}
                    guestEditorRef={guestEditorRef}
                    enableConvertingToManaged={enableConvertingToManaged}
                    onCreateOwner={onCreateOwner}
                    isDialogOpen={isDialogOpen}
                    setIsDialogOpen={setIsDialogOpen}
                    setProductionId={setProductionId}
                    tempProd={tempProd}
                />
            )}
        </FormProvider>
    );
};
