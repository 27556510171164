import React, { useEffect, useContext } from 'react';
import { FormContainer } from '../../common/form/FormContainer';
import { FormContext } from '../../common/form/FormContext';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { useStepper } from '../../common/components/StepperContext';
import { StepsIndexConstant } from '../../common/components/Stepper';

export default () => {
    const { validation, state } = useContext(FormContext);
    const { setStepCompletion, setStepData } = useStepper();

    useEffect(() => {
        if (validation?.isValid) setStepData(StepsIndexConstant.zero, state);
    }, [state, validation?.isValid]);

    useEffect(() => {
        setStepCompletion(StepsIndexConstant.zero, validation?.isValid);
    }, [validation?.isValid]);

    return (
        <FormContainer>
            <FormItem half>
                <FormWidget name="firstName" label={'First name'} />
            </FormItem>
            <FormItem half>
                <FormWidget name="lastName" label={'Last Name'} />
            </FormItem>
        </FormContainer>
    );
};
