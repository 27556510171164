import { cloneDeep, set, isEmpty } from 'lodash';
import {
    ActivityInput,
    DateRange,
    DependencyInput,
    InputMaybe,
    ReferenceInput
} from 'sr-types/lib/production/v1/graphql';
import { Listener } from '../../common/form/FormContext';
import Validator from '../../common/form/Validator';
import { toDisplay } from '../../common/utils/dateTime';
import { EMPTY_DATERANGE } from '../../lab/form/LabForms';
import {
    getDateRangeListeners,
    getDateRangeValidators,
    isStartBeforeEnd
} from '../../supply/booking/ScheduleCalculator';
import { recalculateDuration } from '../../common/components/DateRangeInput';

interface GetDateRangeByActivityTypeParams {
    isMilestone: boolean;
    dateRange: DateRange;
}

interface GetDateRangeByActivityType {
    dateRange: DateRange;
    durationSpecified: boolean;
}

interface AssociateWithResource {
    id: string;
    label: string;
}

export enum ActivityOrder {
    predecessor = 'predecessor',
    successor = 'successor'
}

type ActivityDependency = {
    order: ActivityOrder.predecessor | ActivityOrder.successor;
    activityRefId: string;
    lag: number;
    lagUnit: string;
    type: string;
};

type ActivityDependencies = {
    lastDependency: ActivityDependency;
    [key: string]: ActivityDependency;
};

export type ActivityFormType = ActivityInput & {
    associateWithResource?: AssociateWithResource[];
    dependencies?: ActivityDependencies;
};

export type ActivityEntityFormType = ActivityFormType & {
    productReferences?: ReferenceInput[];
    ownerOrganizationId?: String;
    ownerOrganizationName?: String;
    productionReference?: InputMaybe<ReferenceInput>;
    organizationReference?: InputMaybe<ReferenceInput>;
};

export const getDateRangeByActivityType = ({
    isMilestone,
    dateRange
}: GetDateRangeByActivityTypeParams): GetDateRangeByActivityType => {
    var dateObj = { ...dateRange };
    if (isMilestone) {
        if (dateRange.start) {
            dateObj = { start: dateRange.start, durationSpecified: false };
        } else {
            dateObj = { ...dateRange, durationSpecified: false };
        }
    }
    return {
        durationSpecified: !isMilestone && dateRange.durationSpecified,
        dateRange: dateObj
    };
};

export const EMPTY_DEPENDENCIES: ActivityDependencies = {
    lastDependency: {
        order: ActivityOrder.predecessor,
        activityRefId: null,
        lag: 1,
        lagUnit: 'Days',
        type: 'Start to Start'
    }
};
export const EMPTY_ACTIVITY: ActivityFormType = {
    name: '',
    ...cloneDeep(EMPTY_DATERANGE),
    locations: [],
    category: undefined,
    activityType: 'Task',
    durationSpecified: false,
    identity: { id: '' },
    associateWithResource: [],
    dependencies: { ...cloneDeep(EMPTY_DEPENDENCIES) }
};

export const getDependencyArrayFromState = (dependencies: ActivityDependencies, activityId: string) => {
    const activityDependencies = [];
    if (dependencies && activityId) {
        if (dependencies?.lastDependency?.activityRefId) {
            dependencies[dependencies.lastDependency.activityRefId] = { ...dependencies.lastDependency };
            delete dependencies.lastDependency;
        }
        Object.keys(dependencies).forEach((key) => {
            if (dependencies[key]?.activityRefId) {
                const { activityRefId, lag, lagUnit, type } = dependencies[key];
                if (dependencies[key]?.order === ActivityOrder.predecessor) {
                    const dependency: DependencyInput = {
                        from: { id: activityRefId },
                        to: { id: activityId },
                        lag: lag || 0,
                        lagUnit: lagUnit,
                        type: type
                    };
                    activityDependencies.push(dependency);
                }
                if (dependencies[key]?.order === ActivityOrder.successor) {
                    const dependency: DependencyInput = {
                        from: { id: activityId },
                        to: { id: activityRefId },
                        lag: lag || 0,
                        lagUnit: lagUnit,
                        type: type
                    };
                    activityDependencies.push(dependency);
                }
            }
        });
    }
    return activityDependencies;
};
export const reverseDependencyArrayToObject = (dependencyArray: DependencyInput[], id: string) => {
    const dependencyObject = {};
    if (dependencyArray && id) {
        dependencyArray.forEach((dependency) => {
            const { from, to, lag, lagUnit, type } = dependency;

            if (from && to && from.id && to.id) {
                const activityRefId = id === to.id ? from.id : to.id;
                const order = id === dependency.to.id ? ActivityOrder.predecessor : ActivityOrder.successor;

                dependencyObject[activityRefId] = {
                    order,
                    activityRefId,
                    lag,
                    lagUnit,
                    type
                };
            }
        });
    }

    return dependencyObject;
};

export const getActivityListeners = (): Listener[] => [
    ...getDateRangeListeners('dateRange'),
    {
        prop: `activityType`,
        func: (name, newValue, newState) => {
            if (newValue === 'Milestone') {
                set(newState, 'dateRange.end', '');
            }
        }
    },
    {
        prop: `dateRange.durationSpecified`,
        func: (name, newValue, newState) => {
            if (newValue === true && !newState.dateRange?.start) {
                set(newState, 'dateRange.end', '');
            }
        }
    }
];

export const getStartDateValidators = () => {
    return {
        [`dateRange.start`]: [
            Validator.RULES.isValidDate,
            Validator.Custom((name, state, isModified) => {
                return isStartBeforeEnd('dateRange', state, isModified);
            }, 'Start is after end.'),
            Validator.Custom((name, state, isModified) => {
                const durationSpecified = state.dateRange.durationSpecified;
                const startDateSpecified = Boolean(state.dateRange.start);
                const endDateSpecified = Boolean(state.dateRange.end);
                return durationSpecified || startDateSpecified || !endDateSpecified;
            }, 'Start date needs to be specified')
        ]
    };
};

export const getActivityValidators = () => {
    return {
        ...getDateRangeValidators('dateRange'),
        ...getStartDateValidators(),
        name: [Validator.RULES.isRequired],
        activityType: [Validator.RULES.isRequired]
    };
};

export const getStringFromDateRange = (dateRange: DateRange): string => {
    if (!dateRange) {
        return '';
    }
    let formattedDateRange = '';
    const { start, end, dateTimeDuration, durationSpecified } = dateRange;

    if (start && end && durationSpecified) {
        formattedDateRange = `${toDisplay(start, true)} - ${toDisplay(end, true)} (${dateTimeDuration.value} ${dateTimeDuration.unit.charAt(0).toUpperCase()})`;
    } else if (start && end) {
        formattedDateRange = `${toDisplay(start, true)} - ${toDisplay(end, true)}`;
    } else if (start) {
        formattedDateRange = `${toDisplay(start, true)}`;
    } else if (!start && !durationSpecified) {
        formattedDateRange = '';
    } else if (dateTimeDuration?.value) {
        formattedDateRange = `(${dateTimeDuration.value} ${dateTimeDuration.unit.charAt(0).toUpperCase()})`;
    }

    return formattedDateRange;
};

export const getKeyActivitiesRefs = (
    id: string,
    keyActivities: string[],
    activityList: any[],
    doMark: boolean = false
) => {
    return activityList
        .filter((activity) =>
            doMark
                ? keyActivities.includes(activity.id) || id === activity.id
                : keyActivities.includes(activity.id) && id !== activity.id
        )
        .sort((a, b) => {
            if (a.dateRange?.start < b.dateRange?.start) {
                return -1;
            }
            if (a.dateRange?.start > b.dateRange?.start) {
                return 1;
            }
            return 0;
        })
        .map((item) => {
            return item.reference;
        });
};

export const getDateRangeFromActivities = (linkedActivities, resourceSchedule) => {
    const startDates = [],
        endDates = [];
    linkedActivities.forEach((activity) => {
        if (activity?.dateRange?.start) {
            startDates.push(Number(activity.dateRange.start));
        }
        if (activity?.dateRange?.end) {
            endDates.push(Number(activity.dateRange.end));
        }
    });
    const minStartDate = isEmpty(startDates) ? '' : Math.min(...startDates).toString();
    const maxEndDate = isEmpty(endDates) ? '' : Math.max(...endDates).toString();

    if (!isEmpty(minStartDate) && !isEmpty(maxEndDate)) {
        const durationSpecified = resourceSchedule.dateRange.durationSpecified;
        const resourceDateRange = {
            ...resourceSchedule.dateRange,
            start: minStartDate,
            end: maxEndDate
        };
        resourceSchedule.dateRange = {
            ...recalculateDuration(resourceDateRange),
            durationSpecified: durationSpecified
        };
    }

    return getStringFromDateRange(resourceSchedule.dateRange);
};

export const updateActivityTemplateValues = (activityData: ActivityInput) => {
    const { name, activityType, dateRange, durationSpecified } = activityData;

    return {
        name,
        activityType,
        dateRange,
        durationSpecified
    };
};

export const Activity_Field_Names = {
    basicSection: 'basicSection',
    name: 'name',
    activityType: 'activityType',
    scheduleSection: 'scheduleSection',
    locations: 'locations',
    category: 'category',
    associateWithResource: 'associateWithResource',
    trackOwnerStatusSection: 'trackOwnerStatusSection',
    activityOwner: 'activityOwner',
    activityStatus: 'activityStatus',
    activityDependency: 'activityDependency',
    addActivityButton: 'addActivityButton',
    'dateRange.durationSpecified': 'dateRange.durationSpecified',
    dateRange: 'dateRange',
    detailsSection: 'detailsSection',
    productionReference: 'productionReference',
    productReferences: 'productReferences',
    organizationReference: 'organizationReference'
};

export const handleActivitySaveInput = (state: ActivityFormType, activityId: string = undefined) => {
    const { category, associateWithResource, dependencies, ...otherFields } = state;
    const activityInput: ActivityInput = {
        ...otherFields,
        activityDependencies: getDependencyArrayFromState(dependencies, activityId),
        ...getDateRangeByActivityType({
            isMilestone: otherFields?.activityType === 'Milestone',
            dateRange: otherFields?.dateRange
        })
    };

    if (category?.value) {
        activityInput.category = category;
    }
    return activityInput;
};

export const handleActivityEntitySaveInput = (state: ActivityEntityFormType) => {
    const { organizationReference, associateWithResource, activityDependencies, dependencies, ...otherFields } = state;
    const activityInput: ActivityEntityFormType = {
        ...otherFields,
        ownerOrganizationId: organizationReference?.id || '',
        ownerOrganizationName: organizationReference?.label || ''
    };

    return activityInput;
};
