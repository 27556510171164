import React, { useContext } from 'react';
import OkCancel from '../components/OkCancel';
import { signUp, SignupPayload } from './api';
import { AuthFormHeader, authLinks, HaveAccount } from './AccountLinks';
import I18n, { I18nContext, I18nContextType, useI18n } from '../../common/i18n/I18n';
import useHistory from '../utils/useHistory';
import { Box, Container, Paper } from '@mui/material';
import { FormContainer } from '../form/FormContainer';
import FormItem from '../form/FormItem';
import FormWidget from '../form/FormWidget';
import { handleAndCommitChange } from '../form/forms';
import Nav from '../nav/Nav';
import Validator, { ValidationState } from '../form/Validator';
import { useLocation } from 'react-router-dom';
import { failedToastMessage, successToastMessage } from '../utils/commonUtils';
import PolicyLink from './PolicyLink';
import { V } from '../Layout';

const validationRules = {
    firstName: [Validator.RULES.isRequired],
    lastName: [Validator.RULES.isRequired],
    email: [Validator.RULES.isRequired, Validator.RULES.email],
    mobile: [Validator.RULES.phoneNumber],
    password: [Validator.RULES.isRequired],
    passwordConfirm: [
        Validator.Custom((prop, state) => {
            const password = state.password;
            const value = state[prop];
            return value === password;
        }, 'Needs to match password')
    ]
};

export default () => {
    const { goBack, changeRoute } = useHistory();
    const location = useLocation();
    const username = location.state?.username || '';
    const fName = location.state?.firstName || '';
    const lName = location.state?.lastName || '';
    const socialSignUp = location.state?.socialSignUp || false;
    const i18nContext: I18nContextType = useContext(I18nContext);
    const validator = new Validator(validationRules, {
        // If true validator will keep going even after first field fails validation.
        runAll: false,
        i18nContext: i18nContext
    });

    const [values, setValues] = React.useState<SignupPayload>({
        username: '',
        email: username || '',
        password: '',
        passwordConfirm: '',
        mobile: '',
        firstName: fName || '',
        lastName: lName || ''
    });

    const [valid, setValid] = React.useState<ValidationState>({
        isValid: false,
        errors: {}
    });

    const onChange = (name, value) => {
        handleAndCommitChange(values, name, value, validator, setValues, setValid);
    };

    const onOk = () => {
        // TODO do we need to do this?
        values.username = values.email;
        signUp(values)
            .then(() => {
                successToastMessage('dialog.created');
                changeRoute(authLinks.verify.path, {}, true, { username: values.username });
            })
            .catch((err) => {
                failedToastMessage(`Signup failed: ${err.message}`);
            });
    };

    const onCancel = () => {
        changeRoute(authLinks.login.path);
    };

    const hasErrors = (fieldName) => {
        return Array.isArray(valid.errors[fieldName]) && valid.errors[fieldName].length > 0;
    };

    const title = useI18n('dialog.signup');
    return (
        <>
            <Nav />
            <Container key="signup" sx={{ overflowY: 'auto', height: '100%', maxWidth: 500 }}>
                <V
                    fill
                    sx={{
                        gap: '8px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Paper sx={{ maxWidth: '400px', p: 4 }}>
                        <FormContainer>
                            <FormItem>
                                <AuthFormHeader title={title} />
                            </FormItem>
                        </FormContainer>
                        <FormContainer>
                            <FormItem half>
                                <FormWidget
                                    name="firstName"
                                    label={<I18n token="account.form.firstName" />}
                                    value={values.firstName}
                                    errors={valid.errors.firstName}
                                    hasErrors={hasErrors('firstName')}
                                    onChange={onChange}
                                />
                            </FormItem>
                            <FormItem half>
                                <FormWidget
                                    name="lastName"
                                    label={<I18n token="account.form.lastName" />}
                                    value={values.lastName}
                                    errors={valid.errors.lastName}
                                    hasErrors={hasErrors('lastName')}
                                    onChange={onChange}
                                />
                            </FormItem>
                        </FormContainer>
                        <FormContainer>
                            <FormItem>
                                <FormWidget
                                    name="email"
                                    disabled={!!username}
                                    label={<I18n token="account.form.email" />}
                                    value={values.email}
                                    errors={valid.errors.email}
                                    hasErrors={hasErrors('email')}
                                    onChange={onChange}
                                />
                            </FormItem>
                            <FormItem>
                                <FormWidget
                                    component="PhoneNumber"
                                    name="mobile"
                                    label={<I18n token="account.form.mobileNumber" />}
                                    value={values.mobile}
                                    errors={valid.errors.mobile}
                                    hasErrors={hasErrors('mobile')}
                                    onChange={onChange}
                                />
                            </FormItem>
                        </FormContainer>
                        <FormContainer>
                            <FormItem half>
                                <FormWidget
                                    name="password"
                                    type="password"
                                    label={<I18n token="account.form.password" />}
                                    errors={valid.errors.password}
                                    hasErrors={hasErrors('password')}
                                    onChange={onChange}
                                />
                            </FormItem>
                            <FormItem half>
                                <FormWidget
                                    name="passwordConfirm"
                                    type="password"
                                    label={<I18n token="account.form.confirmPassword" />}
                                    errors={valid.errors.passwordConfirm}
                                    hasErrors={hasErrors('passwordConfirm')}
                                    onChange={onChange}
                                />
                            </FormItem>
                        </FormContainer>
                        <FormContainer>
                            <FormItem>
                                <OkCancel
                                    okLabelI18n="dialog.signup"
                                    onOk={onOk}
                                    onCancel={onCancel}
                                    isOkDisabled={!valid.isValid}
                                />
                            </FormItem>
                            <FormItem>
                                <HaveAccount />
                            </FormItem>
                        </FormContainer>
                    </Paper>
                    <PolicyLink />
                </V>
            </Container>
        </>
    );
};
