import React, { useContext } from 'react';
import FormPanel from '../../common/form/FormPanel';
import FormItem from '../../common/form/FormItem';
import FormWidget from '../../common/form/FormWidget';
import { FormContainer } from '../../common/form/FormContainer';
import { Typography } from '@mui/material';
import { FormContext } from '../../common/form/FormContext';
import { V } from '../../common/Layout';
import I18n from '../../common/i18n/I18n';
import { get, uniqBy } from 'lodash';

export default ({ name, itemsWithNoContact }: { name: string; itemsWithNoContact: any[] }) => {
    const { state } = useContext(FormContext);

    const items = get(state, name, []);

    const uniqueItems = uniqBy(itemsWithNoContact, 'id');

    const findItemIndex = (item) => {
        return items.findIndex((i) => i?.id === item?.id);
    };

    return (
        <FormPanel>
            <Typography fontWeight={'bold'}>
                <I18n token="organization.add.contact.label" />
            </Typography>
            <FormContainer>
                {uniqueItems.length > 0 &&
                    uniqueItems.map((item) => {
                        return (
                            <FormItem key={item?.id} half>
                                <V>
                                    <Typography>{item?.name}</Typography>
                                    <FormItem />
                                    <FormWidget
                                        name={`${name}[${findItemIndex(item)}].contact.contactInfo.email.address`}
                                        label={<I18n token="organization.add.contact.email" />}
                                    />
                                </V>
                            </FormItem>
                        );
                    })}
            </FormContainer>
        </FormPanel>
    );
};
