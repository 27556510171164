import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import I18n from '../../../common/i18n/I18n';
import { Icons } from '../../../common/icons/Icons';

export default ({
    rows,
    rowSelectionModel,
    setFilter,
    setOpenInviteModal,
    showInviteButton,
    setSelectedItems,
    setShowInviteButton
}) => {
    const [isMultipleSelected, setIsMultipleSelected] = useState(false);
    const selectedRowsData = rowSelectionModel.map((id) => rows.find((row) => row.id === id));
    useEffect(() => {
        if (selectedRowsData.length > 0) {
            setShowInviteButton(true);
        } else {
            setShowInviteButton(false);
        }
    }, [rowSelectionModel]);

    useEffect(() => {
        setIsMultipleSelected(selectedRowsData.length > 1);
    }, [selectedRowsData.length]);

    return (
        <Button
            key={'invite'}
            startIcon={<Icons.RequestAvailability />}
            size={'small'}
            disabled={!showInviteButton}
            onClick={() => {
                const selectedRowsData = rowSelectionModel.map((id) => rows.find((row) => row.id === id));
                const filterIds = [];
                setSelectedItems(
                    selectedRowsData.map((row) => {
                        filterIds.push(row.itemReference.id);
                        return { ...row.itemReference, shortlistLineItemId: row.identity.id };
                    })
                );
                setFilter([{ field: 'identity', value: { values: [...filterIds] } }]);
                setOpenInviteModal(true);
            }}
        >
            <I18n token={`form.shortlistLineItem.${isMultipleSelected ? 'sendRequest' : 'inviteButton'}`} />
        </Button>
    );
};
