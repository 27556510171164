import { get, set } from 'lodash';
import { Reference } from '../reference/reference';

export const handleAndCommitChange = (values, id: string, value, validator, setValues, setValid) => {
    const newState = { ...values };
    set(newState, id, value);
    const validation = validator.validate(newState, id);
    setValues(newState);
    setValid({ errors: validation.errors, isValid: validation.isValid });
    return newState;
};

const serviceFieldnameMap = {
    'show.summary.headline': 'headline',
    'organization.summary.headline': 'headline'
};
export const processServiceErrors = (errors, valid, setValid, validator, fieldMap: Object = {}) => {
    errors.forEach((err) => {
        let field = serviceFieldnameMap[err.field.toLowerCase()];
        if (Object.keys(fieldMap).length > 0) {
            field = fieldMap[err.field.toLowerCase()];
        }
        if (field) {
            const errors = valid.errors;
            errors[field] = [err.message];
            validator.invalidate(field, err.message);
            setValid({ isValid: false, errors: errors });
        }
        console.log('Field invalid:', err.field, '=>', err.message);
    });
};

export const hasErrors = (valid, fieldName) => {
    return Array.isArray(get(valid.errors, fieldName)) && get(valid.errors, fieldName).length > 0;
};

export type ReferenceOption = {
    id?: string;
    label: string;
    subLabel?: string;
    isAdd?: boolean;
    ref?: Reference;
    createOption?: boolean;
};

export const referenceToOption = (obj: Reference): ReferenceOption => {
    return {
        id: obj.id,
        label: obj.label,
        ref: obj
    };
};

export const optionToReference = (
    option: ReferenceOption | Array<Reference> | Array<ReferenceOption>,
    multiple: boolean,
    notOnlyRef: boolean
): Reference[] | Reference | ReferenceOption => {
    if (multiple && Array.isArray(option)) {
        return option.map((item) => (!notOnlyRef ? item.ref : item));
    } else if (notOnlyRef && !Array.isArray(option)) {
        return option;
    } else if ('ref' in option) {
        return option.ref;
    }
};

export const isReferenceOptionEqualToValue = (option: ReferenceOption, value) => {
    const valueId = value ? (value.reference ? value.reference.id : value.id) : undefined;
    return option && valueId ? option.id === valueId : false;
};
