import { StepLabel, Theme } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { ReactNode } from 'react';
import { useStyling } from '../Theme';

type StyledStepLabel = {
    children: ReactNode;
    index: number;
    activeStep: number;
};

export default ({ children }: StyledStepLabel) => {
    const { theme, isDarkMode } = useStyling();
    return (
        <StepLabel
            sx={{
                '& .MuiStepLabel-iconContainer .Mui-completed': {
                    color: isDarkMode ? green[300] : green[900]
                },
                '& .MuiStepLabel-iconContainer .Mui-active': {
                    color: theme.palette.primary.main
                }
            }}
        >
            {children}
        </StepLabel>
    );
};
