import { Autocomplete, Chip, createFilterOptions, ListItemText, TextField, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApolloClient, createHttpLink, DocumentNode, gql, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { constants } from '../constants';
import { UserContext } from '../auth/UserContext';
import { authLink } from '../auth/api';
import { arrayToSelectOptions } from '../utils/arrays';
import ChipOptionsRenderer, { RendererType } from '../form/widgets/ChipOptionsRenderer';
import { searchClient, slimResultsQuery } from '../list/slimQuery';
import { isEqual, uniqWith } from 'lodash';

const filter = createFilterOptions();

type Term = {
    text: {
        value: string;
        languageCode: string;
    };
    abbreviation?: string;
    definition?: string;
};

export const metadataClient = new ApolloClient({
    link: authLink.concat(
        createHttpLink({
            uri: '/metadata/v1/'
        })
    ),
    cache: new InMemoryCache({
        addTypename: false
    })
});

export const createTermMutation: DocumentNode = gql`
    mutation AutoCreateTermset($termset: String!, $input: TermInput!) {
        autoCreateTermset(termset: $termset, input: $input) {
            id
            errors {
                field
                message
            }
        }
    }
`;

const TILE_WIDTH = 80;
const PLACEHOLDER_MIN_WIDTH = 42;
const TAG_LIMIT_SIZE = 25;
const AUTOCOMPLETE_INPUT_PADDING = 71;
const TAG_GAP = 4;
const AUTOCOMPLETE_WIDTH_SUBTRAHEND = PLACEHOLDER_MIN_WIDTH + TAG_LIMIT_SIZE + AUTOCOMPLETE_INPUT_PADDING;

export default (props) => {
    const {
        id,
        metadataName: entity,
        inputLabel,
        placeholder,
        value,
        onChange,
        multiple = false,
        disabled = false,
        required = false,
        hasError = undefined,
        errorText = undefined,
        dependentFieldValue,
        allowCreate = false,
        lazyLoad = false,
        pageSize = 5000,
        limitTags = 1,
        optionValues = undefined,
        variant = constants.defaultWidgetVariant,
        size = constants.defaultWidgetSize,
        query = slimResultsQuery(entity),
        filters = [],
        identifier = 'entity',
        simpleCategory = false,
        termset = undefined,
        sortBy = 'name_ASC',
        optionsSort = true,
        testId = undefined,
        ...rest
    } = props;
    const { activeOrganizationAccount } = useContext(UserContext);

    const autocompleteRef = useRef(null);
    const [dynamicLimitTags, setDynamicLimitTags] = useState<number>(-1);

    useEffect(() => {
        if (autocompleteRef.current) {
            updateLimitTags();
        }
        window.addEventListener('resize', updateLimitTags);
        return () => {
            window.removeEventListener('resize', updateLimitTags);
        };
    }, []);

    const updateLimitTags = () => {
        //calculate the number of tags that will be shown inside the Autocomplete input
        // depending on the width of the component
        setDynamicLimitTags(
            Math.floor((autocompleteRef.current.clientWidth - AUTOCOMPLETE_WIDTH_SUBTRAHEND) / (TILE_WIDTH + TAG_GAP))
        );
    };

    const filterArray = [{ identifier, value: entity }, ...filters];

    const toOptionsByEntity = new Map([
        [
            'Category',
            (items) =>
                items.map((item) => {
                    return {
                        label: item.name,
                        subLabel: item.lineage ? item.lineage.value : item.name
                    };
                })
        ],
        [
            'CatalogSubType',
            (items) =>
                items
                    .filter((item) => item.catalogTypes?.includes(dependentFieldValue))
                    .map((item) => {
                        return {
                            label: item.name
                        };
                    })
        ],
        [
            'default',
            (items) =>
                items.map((item) => {
                    return {
                        label: item.name
                    };
                })
        ]
    ]);

    const optionEqualsValueByEntity = new Map([
        [
            'Category',
            (option, value) => {
                return option && value ? option.label === value.label && option.subLabel === value.subLabel : false;
            }
        ],
        [
            'default',
            (option, value) => {
                return option && value ? option.label === value.label : false;
            }
        ]
    ]);

    const toSelectedValueByEntity = new Map([
        [
            'Category',
            (option) => {
                if (simpleCategory) {
                    return option.label;
                } else {
                    return option ? { value: option.label, lineage: { value: option.subLabel } } : null;
                }
            }
        ],
        [
            'default',
            (option) => {
                return option ? (option.inputValue ? option.inputValue : option.label) : null;
            }
        ]
    ]);

    const valueToOptionByEntity = new Map([
        [
            'Category',
            (value) => {
                if (multiple) {
                    return value
                        ? value.map((v) => {
                              if (typeof v === 'string') {
                                  return { label: v, subLabel: v };
                              } else {
                                  return { label: v.value, subLabel: v.lineage ? v.lineage.value : v.value };
                              }
                          })
                        : [];
                } else if (typeof value === 'string') {
                    return { label: value, subLabel: value };
                } else if (value) {
                    return { label: value.value, subLabel: value.lineage ? value.lineage.value : value.value };
                } else {
                    return null;
                }
            }
        ],
        [
            'default',
            (value) => {
                if (multiple) {
                    return value
                        ? value.map((v) => {
                              return { label: v };
                          })
                        : [];
                }
                return value && value !== '' ? { label: value } : null;
            }
        ]
    ]);

    const getByEntity = (map) => {
        return map.has(entity) ? map.get(entity) : map.get('default');
    };

    const [options, setOptions] = useState(optionValues ? arrayToSelectOptions(optionValues) : []);
    const [focused, setFocused] = useState(false);

    const { data, loading, refetch } = useQuery(query, {
        client: searchClient,
        skip: (lazyLoad && !focused) || optionValues,
        variables: { page: { from: 0, size: pageSize }, filters: filterArray, sortBy },
        fetchPolicy: 'cache-first',
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        notifyOnNetworkStatusChange: true
    });

    const [createTerm] = useMutation(createTermMutation, {
        client: metadataClient,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        },
        notifyOnNetworkStatusChange: true
    });

    const sortOptions = (options) => {
        return options.sort((o1, o2) => o1.label.localeCompare(o2.label));
    };

    const loadMore = () => {
        if (data.results.hits.page.pageNumber < data.results.hits.page.totalPages - 1) {
            refetch({
                page: {
                    from: (data.results.hits.page.pageNumber + 1) * data.results.hits.page.size,
                    size: data.results.hits.page.size
                },
                filters: filterArray
            });
        }
    };

    useEffect(() => {
        if (data && data.results.hits && data.results.hits.items) {
            const items = data.results.hits.items;
            if (items.length) {
                const optionsData = uniqWith([...options, ...getByEntity(toOptionsByEntity)(items)], isEqual);
                const sortedOptions = optionsSort && sortOptions(optionsData);
                setOptions(optionsSort ? sortedOptions : optionsData);
            }
        }
    }, [data]);

    useEffect(() => {
        if (dependentFieldValue && data && data.results.hits && data.results.hits.items) {
            const items = data.results.hits.items;
            if (items.length) {
                setOptions([...getByEntity(toOptionsByEntity)(items)]);
            }
        }
    }, [dependentFieldValue, data]);

    const handleChange = (value) => {
        const toSelectedValue = getByEntity(toSelectedValueByEntity);
        if (multiple) {
            onChange(
                rest.name,
                value.map((option) => toSelectedValue(option))
            );
        } else {
            onChange(rest.name, toSelectedValue(value));
        }
    };

    const isOptionEqualToValue = (option, value) => {
        return option && value ? option.label === value : false;
    };

    if (rest.showChips) {
        return (
            <ChipOptionsRenderer
                loading={loading}
                options={options}
                value={value}
                label={inputLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={handleChange}
            />
        );
    } else if (rest.showRadio) {
        return (
            <ChipOptionsRenderer
                rendererType={RendererType.RADIO}
                loading={loading}
                options={options}
                value={value}
                label={inputLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={handleChange}
            />
        );
    } else {
        return (
            <Autocomplete
                data-testid={testId}
                ref={(ref) => (autocompleteRef.current = ref)}
                key={rest.autocompleteKey}
                size={size}
                id={id}
                multiple={multiple}
                selectOnFocus={allowCreate}
                clearOnBlur={allowCreate}
                freeSolo={allowCreate}
                fullWidth
                limitTags={dynamicLimitTags}
                loading={loading}
                options={options}
                disabled={disabled}
                getOptionLabel={(option) => {
                    return option ? (option.inputValue ? option.inputValue : option.label) : '';
                }}
                ListboxProps={{
                    onScroll: (event: React.SyntheticEvent) => {
                        const listBoxNode = event.currentTarget;
                        if (listBoxNode.scrollTop + listBoxNode.clientHeight === listBoxNode.scrollHeight) {
                            loadMore();
                        }
                    }
                }}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props} key={props.id}>
                            <ListItemText primary={option.label} secondary={option.subLabel} />
                        </li>
                    );
                }}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                        return (
                            <Tooltip key={index} title={option.label}>
                                <Chip
                                    size={'small'}
                                    sx={{
                                        width: focused ? 'fit-content' : '80px'
                                    }}
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            </Tooltip>
                        );
                    })
                }
                value={getByEntity(valueToOptionByEntity)(value)}
                isOptionEqualToValue={getByEntity(optionEqualsValueByEntity)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        disabled={disabled}
                        required={required}
                        variant={variant}
                        label={inputLabel}
                        error={hasError}
                        helperText={errorText}
                        InputProps={{
                            ...params.InputProps,
                            size
                        }}
                        placeholder={undefined}
                    />
                )}
                filterSelectedOptions
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (allowCreate) {
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                label: `Add "${inputValue}"`
                            });
                        }
                    }

                    return filtered;
                }}
                onChange={(event, value) => {
                    if (value) {
                        (Array.isArray(value) ? value : [value]).forEach((v) => {
                            if (v.inputValue) {
                                createTerm({
                                    variables: {
                                        termset: entity || termset,
                                        input: {
                                            text: {
                                                value: v.inputValue,
                                                languageCode: 'en'
                                            }
                                        }
                                    }
                                })
                                    .then((res) => {
                                        console.log('Created term:', res);
                                        const newOptions = [
                                            ...options,
                                            {
                                                /*id: res.data.autoCreateTermset.id, */ label: v.inputValue
                                            }
                                        ];
                                        const sortedNewOptions = sortOptions(newOptions);
                                        setOptions(sortedNewOptions);
                                        refetch();
                                    })
                                    .catch((err) => {
                                        console.error('Failed to create term:', err);
                                    });
                            }
                        });
                    }
                    handleChange(value);
                }}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
            />
        );
    }
};
