import { cloneDeep } from 'lodash';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { LineItemInput, NoteInput, Shortlist } from 'sr-types/lib/shortlist/v1/graphql';
import { AllPermissions } from '../common/auth/api';
import { useFeaturePermissions, UserContext } from '../common/auth/UserContext';
import Loading from '../common/components/Loading';
import { Filter } from '../common/components/types';
import { constants } from '../common/constants';
import { FormProvider } from '../common/form/FormContext';
import FormModal from '../common/form/FormModal';
import Validator from '../common/form/Validator';
import I18n, { useI18n } from '../common/i18n/I18n';
import { useGetNote } from '../common/notes/notesApi';
import GuestEditor from '../person/GuestEditor';
import { extractNames } from '../production/helpers/peopleUtils';
import ShortlistLineItemItems, { LineItemType, LineItemTypeEnum } from './form/ShortlistLineItemItems';
import ShortlistLineItemNoteModal from './form/ShortlistLineItemNoteItems';
import { useAddShortlistLineItem, useAddShortlistLineItemWithNote } from './shortlistApi';
import { handleShortlistLineItemResponse, LineItemRows } from './ShortlistLineItems';
import { ItemInfo, shortlistInviteeCrewFilter } from './shortlistLineItem';
import { EnvironmentPermissionContext } from '../common/auth/EnvironmentPermissionContext';
import { Resource } from 'sr-types/lib/production/v1/graphql';
import {
    autoCreateQuery as autoCreateQueryOrganization,
    client as organizationClient,
    EMPTY_STATE as EMPTY_ORG_STATE,
    organizationLineItemSave,
    inputEntityForQuickAddOrg,
    showLocationForQuickAddOrg,
    validationRulesForQuickAddOrg
} from '../organization/organization';
import { useMutationWithContext } from '../common/hooks/useMutationWithContext';
import { QuickAddOrganizationForm } from '../organization/QuickAddOrganizationForm';

type LineItemInfo = LineItemInput & { itemInfo?: ItemInfo };

const EMPTY_SHORTLIST_LINE_ITEM: LineItemInfo = {
    name: undefined,
    itemReference: undefined,
    itemInfo: undefined,
    itemStatus: undefined,
    summary: { about: undefined, headline: undefined },
    defaultNoteReference: {
        id: undefined,
        label: undefined
    }
};

const EMPTY_SHORTLIST_LINE_ITEM_NOTE: NoteInput = {
    content: undefined,
    entityReference: {}
};

export const EMPTY_PERSON_MODAL_STATE = {
    open: false,
    prepopulate: { name: { firstName: '', lastName: '' } }
};

interface ShortlistLineItemEditorProps {
    shortlistId: string;
    isEditorOpen: boolean;
    setIsEditorOpen: Dispatch<SetStateAction<boolean>>;
    lineItem: LineItemRows;
    viewState: Shortlist;
    resourceData: Resource;
    onSuccess: (lineItemEditState: LineItemInput, lineItemId: string, note: string) => void;
}

const defaultValidationRules = {
    name: [Validator.RULES.isRequired]
};

const validationRules = {
    [LineItemTypeEnum.person]: { itemInfo: [Validator.RULES.isRequired] },
    [LineItemTypeEnum.organization]: { itemInfo: [Validator.RULES.isRequired] }
};

export default ({
    shortlistId,
    isEditorOpen,
    setIsEditorOpen,
    lineItem,
    viewState,
    resourceData,
    onSuccess
}: ShortlistLineItemEditorProps) => {
    const { activeOrganizationAccount } = useContext(UserContext);
    const { allowWIP } = useContext(EnvironmentPermissionContext);
    const { addShortlistLineItem, isLineItemSaving } = useAddShortlistLineItem(activeOrganizationAccount);
    const { addShortlistLineItemWithNote, isLineItemWithNotesSaving } =
        useAddShortlistLineItemWithNote(activeOrganizationAccount);
    const { noteData } = useGetNote(
        lineItem?.identity?.id,
        !lineItem || (!lineItem?.defaultNoteReference?.id && !lineItem?.noteExists)
    );
    const canInvite = useFeaturePermissions('Assess Avails')?.[AllPermissions.Manage];

    const [lineItemValues, setLineItemValues] = useState(cloneDeep(EMPTY_SHORTLIST_LINE_ITEM));
    const [lineItemNoteValues, setLineItemNoteValues] = useState(EMPTY_SHORTLIST_LINE_ITEM_NOTE);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [saveDisabled, setIsSaveDisabled] = useState(false);
    const [personModalState, setPersonModalState] = useState(EMPTY_PERSON_MODAL_STATE);
    const [organizationModalState, setOrganizationModalState] = useState(false);
    const [organizationState, setOrganizationState] = useState({ ...EMPTY_ORG_STATE });

    const [save] = useMutationWithContext(autoCreateQueryOrganization, organizationClient);

    const [isFormSaving, setIsFormSaving] = useState(false);
    const guestEditorRef = useRef(null);

    const onSuccessMessage = () => {
        handleShortlistLineItemResponse({ variant: 'success', query: 'save' });
    };
    const onErrorMessage = () => {
        handleShortlistLineItemResponse({ variant: 'error', query: 'save' });
    };

    const lineItemType: LineItemType = useMemo(() => {
        if (viewState?.type) {
            if (viewState.type === 'Crew') {
                return LineItemTypeEnum.person;
            } else if (allowWIP && resourceData) {
                return LineItemTypeEnum.organization;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }, [allowWIP, viewState?.type]);

    const referenceEntityFilters: Filter = useMemo(() => {
        if (viewState?.type) {
            if (viewState.type === 'Crew') {
                return shortlistInviteeCrewFilter(viewState?.roles);
            } else {
                if (resourceData?.locations?.length) {
                    return {
                        shouldFilters: [
                            {
                                mustFilters: [
                                    {
                                        expressions: [
                                            {
                                                field: 'hierarchicalCategories',
                                                value: {
                                                    values: [resourceData.resourceCategory]
                                                }
                                            },
                                            {
                                                field: 'country',
                                                value: {
                                                    values: resourceData.locations.map(
                                                        (location) => location.address?.countryRegionIso2
                                                    )
                                                }
                                            },
                                            {
                                                field: 'locality',
                                                value: {
                                                    values: resourceData.locations.map(
                                                        (location) => location.address?.locality
                                                    )
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                mustFilters: [
                                    {
                                        expressions: [
                                            {
                                                field: 'hierarchicalCategories',
                                                value: {
                                                    values: [resourceData.resourceCategory]
                                                }
                                            },
                                            {
                                                field: 'country',
                                                value: {
                                                    values: ['']
                                                }
                                            },
                                            {
                                                field: 'locality',
                                                value: {
                                                    values: ['']
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                mustFilters: [
                                    {
                                        expressions: [
                                            {
                                                field: 'hierarchicalCategories',
                                                value: {
                                                    values: ['']
                                                }
                                            },
                                            {
                                                field: 'country',
                                                value: {
                                                    values: resourceData.locations.map(
                                                        (location) => location.address?.countryRegionIso2
                                                    )
                                                }
                                            },
                                            {
                                                field: 'locality',
                                                value: {
                                                    values: resourceData.locations.map(
                                                        (location) => location.address?.locality
                                                    )
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        mustFilters: [
                            {
                                expressions: [
                                    { field: 'public', value: { values: ['false'] } },
                                    { field: 'inactive', value: { values: ['false'] } }
                                ]
                            }
                        ]
                    };
                } else {
                    return {
                        mustFilters: [
                            {
                                expressions: [
                                    { field: 'public', value: { values: ['false'] } },
                                    {
                                        field: 'hierarchicalCategories',
                                        value: {
                                            values: [resourceData?.resourceCategory]
                                        }
                                    },
                                    { field: 'inactive', value: { values: ['false'] } }
                                ]
                            }
                        ]
                    };
                }
            }
        } else {
            return undefined;
        }
    }, [viewState.type]);

    const alreadyAddedIs = useMemo(() => {
        if (viewState?.lineItems && Array.isArray(viewState.lineItems) && viewState.lineItems.length) {
            return viewState.lineItems
                .filter((item) => item?.itemReference?.id)
                .map((item) => {
                    return item.itemReference.id;
                });
        }
    }, [viewState.lineItems]);

    useEffect(() => {
        if (lineItem) {
            const {
                id,
                __reorder__,
                isPerson,
                isOrganization,
                hasAccount,
                isItemSharedInDirectory,
                inquiryStatus,
                availability,
                inquiry,
                noteExists,
                ...restLineItemValues
            } = lineItem;
            const editedLineItem: LineItemInfo = {
                ...restLineItemValues,
                name: restLineItemValues.name,
                itemInfo: { ref: restLineItemValues.itemReference, label: restLineItemValues.name }
            };

            setLineItemValues(editedLineItem);
        }
    }, [lineItem]);

    useEffect(() => {
        if (noteData) {
            const note = { ...noteData?.results?.hits?.items[0] };
            const { id, entityReference, content } = note;
            const savedNote: NoteInput = {
                identity: {
                    id
                },
                entityReference,
                content
            };

            setLineItemNoteValues(savedNote);
            setIsDataLoaded(true);
        } else if (!lineItem?.defaultNoteReference?.id && !lineItem?.noteExists) {
            setIsDataLoaded(true);
        }
    }, [noteData]);

    const saveLineItemToShortlist = () => {
        if (personModalState.open) {
            setIsFormSaving(true);
            guestEditorRef.current.handleSave((input, id) => {
                setLineItemValues((prevVal) => {
                    return {
                        ...prevVal,
                        itemInfo: {
                            ref: {
                                id,
                                label: `${input.name.firstName} ${input.name.lastName}`,
                                type: 'Person'
                            },
                            label: `${input.name.firstName} ${input.name.lastName}`
                        },
                        name: `${input.name.firstName} ${input.name.lastName}`
                    };
                });
                setPersonModalState(EMPTY_PERSON_MODAL_STATE);
                setIsFormSaving(false);
            });
        } else if (organizationModalState) {
            setIsFormSaving(true);
            const savedEntity = inputEntityForQuickAddOrg(organizationState, resourceData);

            organizationLineItemSave(
                savedEntity,
                save,
                setIsFormSaving,
                setOrganizationModalState,
                lineItemValues,
                setLineItemValues
            );
        } else {
            const newLineItem =
                canInvite &&
                (lineItemType === LineItemTypeEnum.person || lineItemType === LineItemTypeEnum.organization)
                    ? {
                          ...lineItemValues,
                          name: lineItemValues.itemInfo.label,
                          itemReference: lineItemValues.itemInfo.ref
                      }
                    : { ...lineItemValues, itemReference: {} };
            const itemState = { ...newLineItem };
            delete newLineItem.itemInfo;
            const saveLineItem = lineItemNoteValues.content ? addShortlistLineItemWithNote : addShortlistLineItem;
            saveLineItem({
                shortlistId,
                itemInput: newLineItem,
                noteInput: lineItemNoteValues,
                onClose,
                onSuccess: (id) => {
                    onSuccessMessage();
                    onSuccess(itemState, id, lineItemNoteValues.content);
                },
                onError: onErrorMessage
            });
        }
    };

    const onClose = () => {
        setIsEditorOpen(false);
        setLineItemValues(cloneDeep(EMPTY_SHORTLIST_LINE_ITEM));
        setLineItemNoteValues(cloneDeep(EMPTY_SHORTLIST_LINE_ITEM_NOTE));
    };

    const checkShowLocation = () => {
        return showLocationForQuickAddOrg(organizationState, resourceData);
    };
    const validationRulesForOrgState = useMemo(() => {
        return validationRulesForQuickAddOrg(Validator, organizationState, resourceData);
    }, [organizationState.organizationTypes]);

    const setOrgValues = (e) => {
        setOrganizationModalState(true);
        setOrganizationState({ ...EMPTY_ORG_STATE, publicName: e.label });
    };
    return (
        isEditorOpen && (
            <FormModal
                id={'shortlist-line-items'}
                title={`${organizationModalState ? useI18n('form.organization.add') : `${useI18n('form.shortlist.addLineItem')} ${personModalState.open ? ' / ' + useI18n('form.shortlist.addNewPerson') : ''}`}`}
                isSaving={isLineItemSaving || isLineItemWithNotesSaving || isFormSaving}
                isOpen={true}
                isSaveDisabled={saveDisabled}
                onClose={onClose}
                onSave={saveLineItemToShortlist}
                additionalActions={
                    personModalState.open
                        ? [
                              {
                                  label: useI18n('form.previous'),
                                  onClick: () => {
                                      setPersonModalState(EMPTY_PERSON_MODAL_STATE);
                                  },
                                  variant: 'contained'
                              }
                          ]
                        : []
                }
            >
                {personModalState.open ? (
                    <GuestEditor
                        isInModal={true}
                        entityIdFromModal={constants.createId}
                        setIsSaveDisabled={setIsSaveDisabled}
                        ref={guestEditorRef}
                        prePopulateName={{
                            name: personModalState.prepopulate.name,
                            organizationRole: viewState?.roles?.[0]
                        }}
                        disabledFields={{
                            creativeCredits: { department: true, showReference: true },
                            productionRole: true,
                            experiences: { deparment: true, title: true, organizationReference: true }
                        }}
                    />
                ) : organizationModalState ? (
                    <FormProvider
                        validationRules={validationRulesForOrgState}
                        state={organizationState}
                        setState={setOrganizationState}
                    >
                        <QuickAddOrganizationForm
                            id={'quick-add-shortlist-form'}
                            setIsSaveDisabled={setIsSaveDisabled}
                            checkShowLocation={checkShowLocation}
                        />
                    </FormProvider>
                ) : isDataLoaded ? (
                    <>
                        <FormProvider
                            state={lineItemValues}
                            setState={setLineItemValues}
                            validationRules={
                                canInvite &&
                                (lineItemType === LineItemTypeEnum.person ||
                                    lineItemType === LineItemTypeEnum.organization)
                                    ? validationRules[lineItemType]
                                    : defaultValidationRules
                            }
                        >
                            <ShortlistLineItemItems
                                inquiry={!!lineItem?.inquiry}
                                setIsSaveDisabled={setIsSaveDisabled}
                                onCreateHandle={(e) => {
                                    lineItemType === LineItemTypeEnum.person
                                        ? setPersonModalState({
                                              open: true,
                                              prepopulate: { name: extractNames(e.label) }
                                          })
                                        : lineItemType === LineItemTypeEnum.organization
                                          ? setOrgValues(e)
                                          : undefined;
                                }}
                                addLineItemFilter={referenceEntityFilters}
                                lineItemEntityType={lineItemType}
                                alreadyAddedIs={alreadyAddedIs}
                                showAutocomplete={!!canInvite}
                            />
                        </FormProvider>
                        <FormProvider state={lineItemNoteValues} setState={setLineItemNoteValues}>
                            <ShortlistLineItemNoteModal />
                        </FormProvider>
                    </>
                ) : (
                    <Loading />
                )}
            </FormModal>
        )
    );
};
