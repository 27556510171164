import { get, pick } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { Person } from 'sr-types/lib/person/v1/graphql';
import { Production, Schedule } from 'sr-types/lib/production/v1/graphql';
import { DateRange, Reference } from 'sr-types/lib/resource/v1/graphql';
import { Contact } from 'sr-types/lib/search/v1/graphql';
import { InquiryInput, InquiryMessage, NoteInput } from 'sr-types/lib/shortlist/v1/graphql';
import Validator from '../../../common/form/Validator';
import { ValidationRulesType } from '../../../production/helpers/productionUtils';
import { getDateRangeValidators } from '../../../supply/booking/ScheduleCalculator';
import { ReferenceWithLineItemId } from '../../shortlist';
import { useI18n } from '../../../common/i18n/I18n';
import { useGetOrganizationDetailsForInquiry, useGetPersonDetailsForInquiry } from '../../shortlistApi';
import { Filter } from '../../../common/components/types';

export const PEOPLE_TYPE = 'people';
export const ORGANIZATION_TYPE = 'organizations';

export type PeopleType = ReferenceWithLineItemId & {
    contact: Contact & { id?: string };
};

export type OrganizationType = ReferenceWithLineItemId & {
    contact: Contact & { id?: string };
};

export type CommonInviteFormData = {
    resource: Reference;
    resourceCategory: string;
    production: Production;
    productionSummary: string;
    dateRange: DateRange;
    locations: Location[];
    personalizedMessage?: string;
    productionOptions?: { id: string; label: string; key?: string }[];
};

export type PersonInviteFormData = CommonInviteFormData & {
    people: PeopleType[];
    role: string;
    productionSummary?: string;
};

export type OrganizationInviteFormData = CommonInviteFormData & {
    organizations: OrganizationType[];
    resourceNote: NoteInput & { text: string };
    activities: Reference[];
};

export type InquiryPropsType = {
    type: string;
    entityDetails?: any;
    isEntityLoading?: boolean;
    toInquiryInput?: (state) => InquiryInput[];
    validationRules: ValidationRulesType;
    isModalOpen?: boolean;
    onCloseModal?: () => void;
    data: PersonInviteFormData | OrganizationInviteFormData;
    onSendInquirySuccess?: (editState: InquiryInput[]) => void;
    productionData?: Production;
    personData?: Person;
    isLoading?: boolean;
    inviteeDisabled?: boolean;
    isInStepper?: boolean;
    filters?: Filter
};

export type PersonInviteFormModalProps = {
    isOpen: boolean;
    onClose: () => void;
    isEmailModalOpen: boolean;
    isPreview: boolean;
    setIsPreview: Dispatch<SetStateAction<boolean>>;
    sendInvitation: () => void;
    productionName: string;
    productionDateRange: DateRange;
    productionOptions: { id: string; label: string; key?: string }[];
    peopleWithNoContact: any[];
    onClickContinue: () => void;
    isSaving: boolean;
    isLoading: boolean;
    entityDetails: any;
    getInquiryPreviewData: (options: any) => void;
    isPreviewLoading: boolean;
    previewData: InquiryMessage;
};

export type PersonInviteFormProps = {
    isEmailModalOpen: boolean;
    isPreview: boolean;
    productionName: string;
    productionDateRange: DateRange;
    productionOptions: { id: string; label: string; key?: string }[];
    peopleWithNoContact: any[];
    entityDetails: Person;
    getInquiryPreviewData: (options: any) => void;
    isPreviewLoading: boolean;
    previewData: InquiryMessage;
    peopleDisabled?: boolean;
    filters?: Filter
};

export type OrganizationInviteFormModalProps = {
    isOpen: boolean;
    onClose: () => void;
    isLoading: boolean;
    isSaving: boolean;
    isEmailModalOpen: boolean;
    itemsWithNoEmail: any[];
    data: OrganizationInviteFormData;
    onClickContinue: () => void;
    isPreview: boolean;
    setIsPreview: Dispatch<SetStateAction<boolean>>;
    sendInvitation: () => void;
    entityDetails: any;
    getInquiryPreviewData: (options: any) => void;
    isPreviewLoading: boolean;
    previewData: InquiryMessage;
};

export const personValidationRules: ValidationRulesType = {
    ...getDateRangeValidators('dateRange'),
    people: [Validator.RULES.isRequired],
    production: [Validator.RULES.isRequired],
    role: [Validator.RULES.isRequired],
    locations: [Validator.RULES.isRequired],
    personalizedMessage: [Validator.RULES.isRequired]
};

export const organizationValidationRules: ValidationRulesType = {
    ...getDateRangeValidators('dateRange'),
    locations: [Validator.RULES.isRequired],
    production: [Validator.RULES.isRequired]
};

export const alternateProductionNameValidations = {
    alternateName: [Validator.RULES.isRequired],
    alternateLabel: [Validator.RULES.isRequired]
};

const commonInquiryInputObj = (editState: any, inquiryFor: string) => {
    return {
        assignmentPeriod: {
            ...editState.dateRange
        },
        locations: editState.locations,
        message: editState.personalizedMessage,
        resources: [
            {
                reference: editState.resource,
                name: editState.resource.label,
                category: editState.resourceCategory,
                isPrimary: true
            }
        ],
        InquiryFor: inquiryFor
    };
};

export const toOrgInquiryInput = (editState: OrganizationInviteFormData): InquiryInput[] => {
    const activities = editState?.activities.length
        ? editState.activities.map((activity) => {
              return {
                  name: activity.label,
                  reference: activity
              };
          })
        : [];
    const commonInquiryInputItems = commonInquiryInputObj(editState, 'vendor');
    const inquiryInput: InquiryInput[] = editState.organizations.map((organization) => {
        const updatedInquiryInput = {
            shortlistLineItemId: organization.shortlistLineItemId,
            inquiryPayload: {
                ...commonInquiryInputItems,
                inquiryRecipient: organization.contact,
                production: {
                    name: editState.production.name,
                    reference: editState.production.reference,
                    summary: editState.productionSummary,
                    activities,
                    productionType: editState.production.productionType
                },
                resourceNote: {
                    content: editState.resourceNote.text,
                    reference: editState.resourceNote.entityReference
                }
            }
        };

        return updatedInquiryInput;
    }) as InquiryInput[];

    return inquiryInput;
};

export const toPersonInquiryInput = (editState: PersonInviteFormData): InquiryInput[] => {
    const commonInquiryInputItems = commonInquiryInputObj(editState, 'individual');
    const inquiryInput: InquiryInput[] = editState.people.map((person) => {
        return {
            shortlistLineItemId: person.shortlistLineItemId,
            inquiryPayload: {
                ...commonInquiryInputItems,
                inquiryRecipient: person.contact,
                production: {
                    name: editState.production.name,
                    reference: editState.production.reference,
                    summary: editState.productionSummary,
                    productionType: editState.production.productionType
                },
                role: editState.role
            }
        };
    }) as InquiryInput[];

    return inquiryInput;
};

export const checkForEmail = (
    data: any,
    nameInState: string,
    state: any,
    setValidations: React.Dispatch<React.SetStateAction<{}>>,
    setIsEmailModalOpen: React.Dispatch<React.SetStateAction<{}>>,
    setItemsWithNoEmail: React.Dispatch<React.SetStateAction<{}>>
) => {
    const selectedItems = get(state, nameInState);
    const validations = {};

    selectedItems?.forEach((selectedItem, index) => {
        const itemDetails = data.find((item) => selectedItem.id === item.id);
        const isEmailUnavailableInCrew =
            itemDetails?.reference?.type === 'Person' && !itemDetails?.contactInfo?.email.address;

        const isEmailUnavailableInOrganization =
            itemDetails?.reference?.type !== 'Person' && !itemDetails?.contact?.contactInfo?.email.address;

        if (isEmailUnavailableInCrew || isEmailUnavailableInOrganization) {
            setEmptyContact(selectedItem, itemDetails, setItemsWithNoEmail);
            setContactValidation(index, nameInState, validations, setValidations);
            setIsEmailModalOpen(true);
        } else {
            itemDetails.contact = pick(
                itemDetails.contact,
                'name',
                'isPrimary',
                'contactInfo',
                'role',
                'personReference'
            );
            setIsEmailModalOpen(false);
        }
    });
};

const setEmptyContact = (
    selectedItem: any,
    itemDetails: any,
    setItemsWithNoEmail: React.Dispatch<React.SetStateAction<{}>>
) => {
    const itemDetailsWithNoContact = [];

    selectedItem.contact = {
        contactInfo: {
            email: {
                address: '',
                typeString: 'Business',
                verified: false
            }
        }
    };

    itemDetailsWithNoContact.push(itemDetails);
    setItemsWithNoEmail((prevState: any[]) => [...prevState, ...itemDetailsWithNoContact]);
};

const setContactValidation = (
    index: number,
    name: string,
    validations: any,
    setValidations: React.Dispatch<React.SetStateAction<{}>>
) => {
    validations[`${name}[${index}].contact.contactInfo.email.address`] = [
        Validator.RULES.isRequired,
        Validator.RULES.email
    ];
    setValidations(validations);
};

export const getInquiryPersonalisedMessage = (isCrew: boolean, resourceCategory: string) => {
    return useI18n(isCrew ? 'shortlist.lineItem.person.message' : 'shortlist.lineItem.inquiry.message', {
              resourceCategory: resourceCategory
          });
};

export const getInquiryInviteeData = (isCrew: boolean, filter: any[]) => {
    if (isCrew) {
        const { personData, personLoading } = useGetPersonDetailsForInquiry(filter);
        return { inviteeSearchData: personData ,isSearchLoading: personLoading}
    } else {
        const { organizationData, organizationLoading } = useGetOrganizationDetailsForInquiry(filter);
        return { inviteeSearchData: organizationData ,isSearchLoading: organizationLoading}
    }
};
