import { GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import React, { useContext, useMemo, useState } from 'react';
import { useFeaturePermissions, UserContext } from '../common/auth/UserContext';
import DataGrid from '../common/list/DataGrid';
import { enqueueSnackbar } from '../common/components/Toast';
import I18n, { useI18n } from '../common/i18n/I18n';
import Confirm from '../common/components/Confirm';
import { Typography } from '@mui/material';
import ShortlistLineItemEditor from './ShortlistLineItemEditor';
import { LineItem } from 'sr-types/lib/shortlist/v1/graphql';
import { V } from '../common/Layout';
import { Reference } from 'sr-types/lib/search/v1/graphql';
import WithSidePanel from '../common/components/layout/WithSidePanel';
import Notes from '../common/notes/Notes';
import { Icons } from '../common/icons/Icons';
import useShortlistLineItemsColDefs, { inquiryStatus } from './useShortlistLineItemsColDefs';
import { ALL_PERMISSIONS, AllPermissions } from '../common/auth/api';
import { deleteItemFromState, inquiriesInputToShortlist, lineItemInputToShortlist } from './shortlist';
import {
    defineCommonInquiryProductionData,
    defineShortlistLineItemRows,
    LineItemAdditionalFields,
    productionOtherTitlesToOptions,
    ShortlistLineItemsProps
} from './shortlistLineItem';
import InquiryButton from './form/inquiry/InquiryButton';
import {
    useDeleteLineItemFromShortlist,
    useGetOrganizationDetailsForInquiry,
    useGetPersonDetailsForInquiry
} from './shortlistApi';
import {
    OrganizationInviteFormData,
    organizationValidationRules,
    ORGANIZATION_TYPE,
    PEOPLE_TYPE,
    PersonInviteFormData,
    personValidationRules,
    toOrgInquiryInput,
    toPersonInquiryInput,
    getInquiryPersonalisedMessage,
    getInquiryInviteeData
} from './form/inquiry/inquiry';
import InquiryForm from './form/inquiry/InquiryForm';

export type LineItemRows = LineItem & LineItemAdditionalFields & { __reorder__: string; noteExists?: boolean };

export type LineItemExtended = LineItem & LineItemAdditionalFields;

export const handleShortlistLineItemResponse = ({
    variant,
    query
}: {
    variant: 'success' | 'error';
    query: 'save' | 'delete';
}) => {
    () => enqueueSnackbar(<I18n token={`shortlist.line.items.${query}.${variant}`} />, { variant });
};

export default ({
    shortlistId,
    viewState,
    setViewState,
    resourceData,
    productionData,
    resourceNote
}: ShortlistLineItemsProps) => {
    const [filter, setFilter] = useState([]);

    const { deleteShortlistLineItem, isLineItemDeleting } = useDeleteLineItemFromShortlist();

    const featurePermissions = useFeaturePermissions('Shortlist');
    const canInvite = useFeaturePermissions('Assess Avails')?.[AllPermissions.Manage];
    const entityPermissions = ALL_PERMISSIONS;
    const isTypeCrew = resourceData?.type === 'Crew';
    const { inviteeSearchData, isSearchLoading } = getInquiryInviteeData(isTypeCrew, filter);

    const [isOpen, setIsOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [lineItemIndex, setLineItemIndex] = useState(undefined);
    const [lineitemToDelete, setLineItemToDelete] = useState(undefined);
    const [entityReference, setEntityReference] = useState<Reference>();
    const [panelTitle, setPanelTitle] = useState<string>();
    const [showInviteButton, setShowInviteButton] = useState<boolean>(false);
    const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const personalizedMessage = getInquiryPersonalisedMessage(isTypeCrew, resourceData?.resourceCategory);

    const commonProps = {
        isModalOpen: openInviteModal,
        onCloseModal: () => setOpenInviteModal(false),
        onSendInquirySuccess: (inquiriesInput) => sendingInquiryOnSuccess(inquiriesInput),
        filter
    };

    const productionOptions = useMemo(
        () => productionOtherTitlesToOptions(productionData?.otherTitles),
        [productionData?.otherTitles]
    );
    const commonInquiryProductionData = useMemo(() => {
        return (
            resourceData &&
            productionData &&
            defineCommonInquiryProductionData(resourceData, productionData, productionOptions, personalizedMessage)
        );
    }, [resourceData]);

    const panels = [
        {
            id: 'notes',
            icon: Icons.Notes,
            label: 'Notes',
            tooltip: entityReference ? <I18n token="notes.close" /> : 'notes.grid',
            disabled: !entityReference,
            onClick: () => {
                return new Promise<void>((resolve) => {
                    setEntityReference(undefined);
                    resolve();
                });
            },
            component: (onClose) => (
                <Notes
                    id={'notes-lab'}
                    title={panelTitle}
                    entityReference={entityReference}
                    onClose={() => {
                        setEntityReference(undefined);
                        onClose();
                    }}
                    featurePermissions={featurePermissions}
                    entityPermissions={entityPermissions}
                />
            )
        }
    ];

    const columns: GridColDef[] = useShortlistLineItemsColDefs(
        (row) => {
            setEntityReference({
                deleted: false,
                eventId: '',
                id: row.identity.id,
                type: 'ShortlistLineItem',
                uriPrefix: 'lineItems'
            });
            setPanelTitle(`Notes for ${row['name']}`);
        },
        (row) => {
            setSelectedItems([{ ...row.itemReference, shortlistLineItemId: row.identity.id }]);
            setFilter([{ field: 'identity', value: { values: row.itemReference.id } }]);
            setOpenInviteModal(true);
        },
        !!(resourceData && productionData && canInvite)
    );

    const rows: LineItemRows[] = defineShortlistLineItemRows(
        viewState,
        useI18n('lineItems.availability.availableFrom')
    );

    const isRowSelectable = React.useCallback(
        (params: GridRowParams) => {
            if (
                (!params.row?.isPerson && !params.row?.isOrganization) ||
                (params.row?.inquiryStatus && inquiryStatus[params.row?.inquiryStatus]?.disabled)
            )
                return false;
            if (rowSelectionModel.includes(params.id)) return true;
            return true;
        },
        [rowSelectionModel]
    );

    const deleteLineItem = () => {
        deleteShortlistLineItem({
            shortlistId,
            lineItemId: rows[lineitemToDelete]?.identity.id
        })
            .then((res: any) => {
                if (!res.data.deleteLineItem.errors) {
                    setViewState((prevVal) => {
                        return deleteItemFromState(rows[lineitemToDelete]?.identity.id, prevVal);
                    });
                    handleShortlistLineItemResponse({ variant: 'success', query: 'delete' });
                } else {
                    handleShortlistLineItemResponse({ variant: 'error', query: 'delete' });
                }
            })
            .finally(() => {
                setDeleteConfirmationOpen(false);
            });
    };

    const sendingInquiryOnSuccess = (inquiriesInput) => {
        setViewState((prevVal) => {
            return inquiriesInputToShortlist(inquiriesInput, prevVal);
        });

        setRowSelectionModel([]);
        setOpenInviteModal(false);
    };

    return (
        <WithSidePanel
            visibleSidePanelId={entityReference ? 'notes' : undefined}
            panels={panels}
            leftToolbar={undefined}
            hideHeader={true}
        >
            <V sx={{ height: '100%', minWidth: '1px', position: 'relative' }}>
                <DataGrid
                    id={'shortlist-lineitems'}
                    loading={isLineItemDeleting}
                    feature={'Shortlist'}
                    addTitle={<I18n token="shortlist.line.item.add" />}
                    rowCount={rows?.length || 0}
                    rows={rows ? rows : []}
                    columns={columns}
                    toolbar={{
                        add: true,
                        custom:
                            resourceData && productionData && canInvite
                                ? [
                                      <InquiryButton
                                          rows={rows}
                                          rowSelectionModel={rowSelectionModel}
                                          setFilter={setFilter}
                                          setOpenInviteModal={setOpenInviteModal}
                                          showInviteButton={showInviteButton}
                                          setSelectedItems={setSelectedItems}
                                          setShowInviteButton={setShowInviteButton}
                                      />
                                  ]
                                : []
                    }}
                    onAdd={() => {
                        setIsOpen(true);
                        setLineItemIndex(undefined);
                    }}
                    onEdit={(index) => {
                        setIsOpen(true);
                        setLineItemIndex(index);
                    }}
                    onDelete={(lineItemId: string) => {
                        setLineItemToDelete(lineItemId);
                        setDeleteConfirmationOpen(true);
                    }}
                    disablePagination
                    checkboxSelection={!!(resourceData && productionData && canInvite)}
                    disableRowSelectionOnClick
                    isRowSelectable={isRowSelectable}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={setRowSelectionModel}
                    asIsColumns={['icons']}
                />
            </V>

            {isOpen && (
                <ShortlistLineItemEditor
                    shortlistId={shortlistId}
                    isEditorOpen={true}
                    setIsEditorOpen={setIsOpen}
                    lineItem={rows?.[lineItemIndex]}
                    viewState={viewState}
                    resourceData={resourceData}
                    onSuccess={(lineItemEditState, lineItemId, note) => {
                        setViewState((prevVal) => {
                            return lineItemInputToShortlist(lineItemEditState, lineItemId, prevVal, note);
                        });
                        setIsOpen(false);
                    }}
                />
            )}

            {openInviteModal && canInvite && isTypeCrew && (
                <>
                    <InquiryForm
                        type={PEOPLE_TYPE}
                        toInquiryInput={toPersonInquiryInput}
                        validationRules={personValidationRules}
                        entityDetails={inviteeSearchData?.search?.hits}
                        isEntityLoading={isSearchLoading}
                        data={
                            {
                                people: selectedItems,
                                role: viewState?.roles?.[0],
                                ...commonInquiryProductionData
                            } as PersonInviteFormData
                        }
                        {...commonProps}
                    />
                </>
            )}

            {openInviteModal && canInvite && !isTypeCrew && selectedItems.length && (
                <>
                    <InquiryForm
                        type={ORGANIZATION_TYPE}
                        toInquiryInput={toOrgInquiryInput}
                        validationRules={organizationValidationRules}
                        entityDetails={inviteeSearchData?.search?.hits}
                        isEntityLoading={isSearchLoading}
                        data={
                            {
                                organizations: selectedItems,
                                activities: [],
                                resourceNote: { ...resourceNote, text: '' },
                                ...commonInquiryProductionData
                            } as OrganizationInviteFormData
                        }
                        {...commonProps}
                    />
                </>
            )}

            <Confirm
                open={deleteConfirmationOpen}
                title={<I18n token="delete.confirm.title" />}
                okLabelI18n="delete.confirm.title"
                body={
                    <Typography>
                        <I18n token="delete.confirm.message" />
                    </Typography>
                }
                onConfirm={deleteLineItem}
                onCancel={() => {
                    setDeleteConfirmationOpen(false);
                }}
            />
        </WithSidePanel>
    );
};
